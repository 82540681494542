import React from 'react'

const Contact = () => {
  return (
    <div className="contact-b backdrop-blur">
    <div className="grid mx-auto h-scree py-8 container grid-cols-2 gap-4 xl:gap-8 ">
            

    <div className="col-span-full mx-auto w-[90%] md:col-span-1 md:order-first grid self-center xl:pl-8">
    <div className="text-4xl text-left flex flex-col Product-Sans my-10 font-bold">
<span className="text-2xl text-left  Product-Sans  font-semibold bg-clip-text block  text-transparent bg-gradient-to-r from-red-600 to-red-500">
Contact Us </span>
Easy to contact us
<div className="text-sm text-left text-gray-500 font-medium mt-3 mont">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Commodi ullam consectetur, magni quisquam.</div>
</div>
<div className="grid grid-cols-2 gap-4">
    <div className="col-span-full lg:col-span-1 flex items-center gap-4 p-4 rounded-md border"><span className="p-4 rounded-lg bg-[#FEE2E2] flex justify-center items-center md:h-10 md:w-10 xl:h-12 xl:w-12"><i class="ri-phone-fill text-2xl bg-clip-text block  text-transparent bg-gradient-to-r from-red-600 to-red-500 "></i></span> <span className="grid font-semibold Product-Sans items-center text-xl">Call <span className="font-medium Product-Sans text-[0.9rem] 2xl:text-base">021 123 145 14</span></span></div>
    <div className="col-span-full lg:col-span-1 flex items-center gap-4 p-4 rounded-md border overflow-wrap"><span className="p-4 rounded-lg bg-[#FEE2E2] flex justify-center items-center md:h-10 md:w-10 xl:h-12 xl:w-12"><i class="ri-at-line text-2xl bg-clip-text block  text-transparent bg-gradient-to-r from-red-600 to-red-500"></i></span> <span className="grid font-semibold Product-Sans items-center text-xl">Email <span className="font-medium Product-Sans text-[0.9rem] 2xl:text-base break-all">contact@radmedia.com</span></span></div>
    <div className="col-span-full  flex items-center gap-4 p-4 rounded-md border"><span className="p-4 rounded-lg bg-[#FEE2E2] flex justify-center items-center md:h-10 md:w-10 xl:h-12 xl:w-12"><i class="ri-map-pin-user-fill text-2xl bg-clip-text block  text-transparent bg-gradient-to-r from-red-600 to-red-500"></i></span> <span className="grid font-semibold Product-Sans items-center text-xl">Address <span className="font-medium Product-Sans text-[0.9rem] 2xl:text-base">PBR Towers, Sevoke Road, Ward 42, Don Bosco Colony, Siliguri, West Bengal - 734004.</span></span></div>
</div>

    </div>
    
    <div className="col-span-full md:col-span-1 md:order-first grid self-center">
    <div class="bg-lime-00  md:order-first col-span-full md:col-span-1 flex justify-center  xl:pl-10 ">

{/* <div className="bg-gray-30 mx-auto w-[65%] md:w-[90%] xl:w-3/4 h-[45vh] lg:h-[78vh] mb-8 md:mb-8 rounded-t-full  border-8 bg-cover bg-center overflow-hidden shadow-inner shadow-gray-400" id="contact-img" >
</div> */}
 <div class=" bg-white flex flex-col justify-center w-[90%] md:py-8 mt-8 md:mt-0">
      <h2 class="text-gray-900 text-lg mb-1 font-medium title-font">Write to us.</h2>
      <p class="leading-relaxed mb-5 text-gray-600">Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores reprehenderit itaque.</p>
      <div class="relative mb-4">
        <label for="name" class="leading-7 text-sm text-gray-600">Name</label>
        <input type="text" id="name" name="name" class="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"/>
      </div>
      <div class="relative mb-4">
        <label for="email" class="leading-7 text-sm text-gray-600">Email</label>
        <input type="email" id="email" name="email" class="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"/>
      </div>
      <div class="relative mb-4">
        <label for="message" class="leading-7 text-sm text-gray-600">Message</label>
        <textarea id="message" name="message" class="w-full bg-white rounded border border-gray-300 focus:border-red-500 focus:ring-2 focus:ring-indigo-200 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"></textarea>
      </div>
      <button class="text-white bg-gradient-to-r from-red-800 to-red-500 border-0 py-2 px-6 focus:outline-none hover:bg-red-600 rounded text-lg">Button</button>
      <p class="text-xs text-gray-500 mt-3">Chicharrones blog helvetica normcore iceland tousled brook viral artisan.</p>
    </div>
</div>
    </div>
    </div>
    </div>
  )
}

export default Contact