import React from 'react'
import HeroSection from './HeroSection'
import ProductSlider from './ProductSlider'
import Faq from './Faq'
import Cta from './Cta'
import LogoCloud from './LogoCloud'
import Services from './Services'

const Home = () => {
  return (
    <div>
         <HeroSection/>
     {/* <ProductSlider /> */}
     <Services/>
     <Faq/>
     <LogoCloud/>
     <Cta/>
    </div>
  )
}

export default Home