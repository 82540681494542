import React from 'react'

const Work = () => {
  return (
    <div>

<section class="text-gray-600 body-font">
  <div class="container px-5 py-24 mx-auto">
    <div class="flex flex-wrap w-full mb-20">
      <div class="lg:w-1/2 w-full mb-6 lg:mb-0">
        <h1 class="sm:text-3xl text-2xl font-medium title-font mb-2 text-gray-900">Our Work Portfolio</h1>
        <div class="h-1 w-20 bg-red-500 rounded"></div>
      </div>
      <p class="lg:w-1/2 w-full leading-relaxed text-gray-500">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Deserunt repudiandae quia pariatur consectetur, in dolore perspiciatis voluptatum odio? Temporibus, corrupti eos neque quae nobis fugiat!</p>
    </div>

<div className="flex justify-center items-center gap-4 my-10   ">
    <span className="px-4 py-1 text-white rounded-full hover:bg-red-700 bg-gray-700">Website</span>
    <span className="px-4 py-1 text-white rounded-full hover:bg-red-700 bg-gray-700">Logo</span>
    <span className="px-4 py-1 text-white rounded-full hover:bg-red-700 bg-gray-700">Social Media</span>
</div>

    <div class="flex flex-wrap -m-4">
    <div class="xl:w-1/4 md:w-1/2 p-4">
        <div class="bg-gray-100 p-6 rounded-lg">
          <img class="h-40 md:h-60 xl:h-40 rounded w-full object-cover object-center mb-6" src="https://dummyimage.com/723x403" alt="content"/>
          <h3 class="tracking-widest text-indigo-500 text-xs font-medium title-font">WEBSITE</h3>
          <h2 class="text-lg text-gray-900 font-medium title-font mb-4">Project Name</h2>
          <p class="leading-relaxed text-base">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Repellendus rem modi ea.</p>
        </div>
      </div>
      <div class="xl:w-1/4 md:w-1/2 p-4">
        <div class="bg-gray-100 p-6 rounded-lg">
          <img class="h-40 md:h-60 xl:h-40 rounded w-full object-cover object-center mb-6" src="https://dummyimage.com/723x403" alt="content"/>
          <h3 class="tracking-widest text-indigo-500 text-xs font-medium title-font">WEBSITE</h3>
          <h2 class="text-lg text-gray-900 font-medium title-font mb-4">Project Name</h2>
          <p class="leading-relaxed text-base">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Repellendus rem modi ea.</p>
        </div>
      </div>
      <div class="xl:w-1/4 md:w-1/2 p-4">
        <div class="bg-gray-100 p-6 rounded-lg">
          <img class="h-40 md:h-60 xl:h-40 rounded w-full object-cover object-center mb-6" src="https://dummyimage.com/723x403" alt="content"/>
          <h3 class="tracking-widest text-indigo-500 text-xs font-medium title-font">WEBSITE</h3>
          <h2 class="text-lg text-gray-900 font-medium title-font mb-4">Project Name</h2>
          <p class="leading-relaxed text-base">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Repellendus rem modi ea.</p>
        </div>
      </div>
      <div class="xl:w-1/4 md:w-1/2 p-4">
        <div class="bg-gray-100 p-6 rounded-lg">
          <img class="h-40 md:h-60 xl:h-40 rounded w-full object-cover object-center mb-6" src="https://dummyimage.com/723x403" alt="content"/>
          <h3 class="tracking-widest text-indigo-500 text-xs font-medium title-font">WEBSITE</h3>
          <h2 class="text-lg text-gray-900 font-medium title-font mb-4">Project Name</h2>
          <p class="leading-relaxed text-base">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Repellendus rem modi ea.</p>
        </div>
      </div>
    </div>
  </div>
</section>


    </div>
  )
}

export default Work