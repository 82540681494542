import React from 'react'
import t2tLogo from '../Images/t2tLogo.svg'

const LogoCloud = () => {
  return (



<div class="relative flex flex-col items-center overflow-hidden py-20 lg:py-12 mt-10  md:py-40 bg-red-50 bg-cover opacity-" id='logocloud-img'>
<div class="container relative z-[1] m-auto px-6 md:px-12">
  <div class="m-auto text-center md:w-8/12 lg:w-6/12">
    <h2 class="text-xl font-semibold bg-clip-text block  text-transparent bg-gradient-to-r from-red-600 to-red-500 Product-Sans   mb-2">
    Premium Partner
    </h2>
    <h3 className='text-gray-800 Product-Sans text-lg md:text-2xl'>Trusted Partner We Proudly Collaborate With</h3>
  </div>
  <div class="m-auto mt-10 md:w-11/12 lg:w-8/12 xl:w-7/12">
    <div class="flex flex-wrap justify-center gap-6">
      <div
        class="group rounded-xl border border-red-200  bg-white bg-opacity-5 py-2 px-4 hover:bg-opacity-10"
      >
        <a href="https://www.truth2it.com/" target='is_blank'>
        <img
          src={t2tLogo}
          class="contrast- h-20 md:h-24 px-5 py-3 transition group-hover:contrast-100"
          loading="lazy"
          alt="logo Truth2It"
        /></a>
      </div>
     
    </div>
  </div>
</div>
</div>
                                  
                                    
  )
}

export default LogoCloud