import React, { useState } from 'react'
import Hafele from "../Images/Hafele.jpg"
import Sleek from "../Images/Sleek.jpg"
import Hettich from "../Images/Hettich.jpg"
import { Link } from 'react-router-dom'


const Hardware = () => {


  //Marco
  const [imagesMarco, setImagesMarco] = useState({
    img1 : require("../Images/ply.jpg"),
    img2 : require("../Images/ply2.jpg"),
    img3 : require("../Images/ply3.jpg"),
    img4 : require("../Images/ply.jpg"),
})

const [activeMarcoImg, setActiveMarcoImage] = useState(imagesMarco.img1)

//Vaastu
const [imagesVaastu, setImagesVaastu] = useState({
  img1 : require("../Images/ply.jpg"),
    img2 : require("../Images/ply2.jpg"),
    img3 : require("../Images/ply3.jpg"),
    img4 : require("../Images/ply.jpg"),
})

const [activeVaastuImg, setActiveVaastuImage] = useState(imagesVaastu.img1)

//Saburi
const [imagesSaburi, setImagesSaburi] = useState({
  img1 : require("../Images/ply.jpg"),
    img2 : require("../Images/ply2.jpg"),
    img3 : require("../Images/ply3.jpg"),
    img4 : require("../Images/ply.jpg"),
})

const [activeSaburiImg, setActiveSaburiImage] = useState(imagesSaburi.img1)

//Handles
const [imagesHandles, setImagesHandles] = useState({
  img1 : require("../Images/ply.jpg"),
    img2 : require("../Images/ply2.jpg"),
    img3 : require("../Images/ply3.jpg"),
    img4 : require("../Images/ply.jpg"),
})

const [activeHandlesImg, setActiveHandlesImage] = useState(imagesHandles.img1)


  return (
    <div className=" grid items-center  ">
      <div className="bg-gray-800">
      <div className=" container mx-auto">
<nav aria-label="breadcrumb" className="w- p-4  bg-gray-800 text-gray-100">
	<ol className="flex h-8 space-x-2">
		<li className="flex items-center">
    <Link  to="/home"  title="Back to homepage" className="hover:no-underline">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5 pr-1 dark:text-gray-400">
					<path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path>
				</svg>
			</Link>
		</li>
		<li className="flex items-center space-x-2">
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" aria-hidden="true" fill="currentColor" className="w-2 h-2 mt-1 transform rotate-90 fill-current dark:text-gray-600">
				<path d="M32 30.031h-32l16-28.061z"></path>
			</svg>
			<Link  to="/products" className="flex items-center px-1 capitalize hover:no-underline">Products</Link>
		</li>
		<li className="flex items-center space-x-2">
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" aria-hidden="true" fill="currentColor" className="w-2 h-2 mt-1 transform rotate-90 fill-current dark:text-gray-600">
				<path d="M32 30.031h-32l16-28.061z"></path>
			</svg>
			<Link  to="/hardware" className="flex items-center px-1 capitalize hover:no-underline">Hardware Fittings</Link>
		</li>
		
	</ol>
</nav>
</div>
</div>
      {/* <div className="Product-Sans text-4xl mt-10 px-4 md:px-4 font-bold mx-auto container bg-clip-text block  text-transparent bg-gradient-to-r from-amber-600 to-amber-500">
        <span>Plywoods</span> </div> */}
     

{/* Baskets */}
        <div className="grid grid-cols-2  px-8 md:px-0  py-10 justify-center container mx-auto gap-10">
          <div className="col-span-full md:col-span-1 anim-product">
          <div className='flex flex-col gap-2 md:gap-6 '>
                <img src={activeMarcoImg} alt="" className='w-full h-[20rem] md:h-[32rem] aspect-square object-cover rounded-xl'/>
                <div className='flex flex-row justify-between md:justify-evenly h-24'>
                    <img src={imagesMarco.img1} alt="" className='w-20 md:w-24 h-20 md:h-24 rounded-md cursor-pointer' onClick={() => setActiveMarcoImage(imagesMarco.img1)}/>
                    <img src={imagesMarco.img2} alt="" className='w-20 md:w-24 h-20 md:h-24 rounded-md cursor-pointer' onClick={() => setActiveMarcoImage(imagesMarco.img2)}/>
                    <img src={imagesMarco.img3} alt="" className='w-20 md:w-24 h-20 md:h-24 rounded-md cursor-pointer' onClick={() => setActiveMarcoImage(imagesMarco.img3)}/>
                    <img src={imagesMarco.img4} alt="" className='w-20 md:w-24 h-20 md:h-24 rounded-md cursor-pointer' onClick={() => setActiveMarcoImage(imagesMarco.img4)}/>
                </div>
            </div>
          </div>
          <div className="col-span-full md:col-span-1 flex items-center animate-slide-fade-product">
          <div className='flex flex-col Product-Sans gap-4 '>
                <div>
                    <span className='  bg-clip-text block font-semibold text-transparent bg-gradient-to-r from-amber-600 to-amber-500'>Premium Product</span>
                    <h1 className='text-3xl font-bold'>Baskets</h1>
                </div>
                <p className='text-gray-600 md:text-base xl:text-lg font-medium'>
                Perfect storage solution with our hardware steel baskets. Designed for durability and functionality, our baskets are crafted from high-quality steel, ensuring long-lasting performance. Organise and declutter your space effortlessly, whether it's in your kitchen, pantry, garage, or office. With their sleek and versatile design, our steel baskets add a touch of modern sophistication to any setting. Experience the convenience and efficiency of our hardware steel baskets today.

                </p>

                <div className='flex flex-row items-center mt-4 gap-12'>
                <Link to="/distributers" className=' text-white font-semibold py-3 cursor-pointer w-full md:w-1/3 bg-gradient-to-r from-amber-800 to-amber-500 text-center  rounded-xl h-full'>Our Vendors</Link>
                </div>
            </div>
          </div>
        </div>

{/* Channels */}
        <div className="grid grid-cols-2 px-8 md:px-0  py-10 justify-center container mx-auto gap-10 ">
        <div className="col-span-full order-last md:order-first md:col-span-1 flex items-center animate-slide-fade-product">
          <div className='flex flex-col Product-Sans gap-4 '>
                <div>
                    <span className=' text-violet-60 bg-clip-text block font-semibold text-transparent bg-gradient-to-r from-amber-600 to-amber-500'>Premium Product</span>
                    <h1 className='text-3xl font-bold'>Channels</h1>
                </div>
                <p className='text-gray-600 md:text-base xl:text-lg  font-medium'>
                Experience effortless organisation with our premium drawer slides/channels. Crafted for smooth operation, they transform cabinets and drawers with ease. Choose from durable options for kitchens, offices, and bedrooms. Say goodbye to frustrating jams and enjoy seamless functionality. Upgrade your space today!


                </p>

              

                <div className='flex flex-row items-center mt-4 gap-12'>
                <Link to="/distributers" className=' text-white font-semibold py-3 cursor-pointer w-full md:w-1/3 bg-gradient-to-r from-amber-800 to-amber-500 text-center  rounded-xl h-full'>Our Vendors</Link>
                </div>
            </div>
          </div>

          <div className="col-span-full order-first md:order-last md:col-span-1 anim-product ">
          <div className='flex flex-col gap-2 md:gap-6 '>
                <img src={activeVaastuImg} alt="" className='w-full h-[20rem] md:h-[32rem] aspect-square object-cover rounded-xl'/>
                <div className='flex flex-row justify-between md:justify-evenly h-24'>
                    <img src={imagesVaastu.img1} alt="" className='w-20 md:w-24 h-20 md:h-24 rounded-md cursor-pointer' onClick={() => setActiveVaastuImage(imagesVaastu.img1)}/>
                    <img src={imagesVaastu.img2} alt="" className='w-20 md:w-24 h-20 md:h-24 rounded-md cursor-pointer' onClick={() => setActiveVaastuImage(imagesVaastu.img2)}/>
                    <img src={imagesVaastu.img3} alt="" className='w-20 md:w-24 h-20 md:h-24 rounded-md cursor-pointer' onClick={() => setActiveVaastuImage(imagesVaastu.img3)}/>
                    <img src={imagesVaastu.img4} alt="" className='w-20 md:w-24 h-20 md:h-24 rounded-md cursor-pointer' onClick={() => setActiveVaastuImage(imagesVaastu.img4)}/>
                </div>
            </div>
          </div>
         
        </div>

{/* Hinges */}
<div className="grid grid-cols-2  px-8 md:px-0  py-10 justify-center container mx-auto gap-10">
          <div className="col-span-full md:col-span-1 anim-product">
          <div className='flex flex-col gap-2 md:gap-6 '>
                <img src={activeSaburiImg} alt="" className='w-full h-[20rem] md:h-[32rem] aspect-square object-cover rounded-xl'/>
                <div className='flex flex-row justify-between md:justify-evenly h-24'>
                    <img src={imagesSaburi.img1} alt="" className='w-20 md:w-24 h-20 md:h-24 rounded-md cursor-pointer' onClick={() => setActiveSaburiImage(imagesSaburi.img1)}/>
                    <img src={imagesSaburi.img2} alt="" className='w-20 md:w-24 h-20 md:h-24 rounded-md cursor-pointer' onClick={() => setActiveSaburiImage(imagesSaburi.img2)}/>
                    <img src={imagesSaburi.img3} alt="" className='w-20 md:w-24 h-20 md:h-24 rounded-md cursor-pointer' onClick={() => setActiveSaburiImage(imagesSaburi.img3)}/>
                    <img src={imagesSaburi.img4} alt="" className='w-20 md:w-24 h-20 md:h-24 rounded-md cursor-pointer' onClick={() => setActiveSaburiImage(imagesSaburi.img4)}/>
                </div>
            </div>
          </div>
          <div className="col-span-full md:col-span-1 flex items-center animate-slide-fade-product">
          <div className='flex flex-col Product-Sans gap-4 '>
                <div>
                    <span className='  bg-clip-text block font-semibold text-transparent bg-gradient-to-r from-amber-600 to-amber-500'>Premium Product</span>
                    <h1 className='text-3xl font-bold'>Hinges</h1>
                </div>
                <p className='text-gray-600 md:text-base xl:text-lg  font-medium'>
                Discover the perfect hinge solution for your doors and cabinets. Our hinges offer durability and seamless functionality, ensuring smooth operation every time. With a variety of styles and finishes, we have the ideal hinge to complement any decor. Upgrade your space with our high-quality hinges today!



                </p>

           

                <div className='flex flex-row items-center mt-4 gap-12'>
                <Link to="/distributers" className=' text-white font-semibold py-3 cursor-pointer w-full md:w-1/3 bg-gradient-to-r from-amber-800 to-amber-500 text-center  rounded-xl h-full'>Our Vendors</Link>
                </div>
            </div>
          </div>
        </div>


        {/* Handles */}
        <div className="grid grid-cols-2 px-8 md:px-0  py-10 justify-center container mx-auto gap-10 ">
        <div className="col-span-full order-last md:order-first md:col-span-1 flex items-center animate-slide-fade-product">
          <div className='flex flex-col Product-Sans gap-4 '>
                <div>
                    <span className=' text-violet-60 bg-clip-text block font-semibold text-transparent bg-gradient-to-r from-amber-600 to-amber-500'>Premium Product</span>
                    <h1 className='text-3xl font-bold'>Handles</h1>
                </div>
                <p className='text-gray-600 md:text-base xl:text-lg  font-medium'>
                Elevate the style and functionality of your doors, cabinets, and more with our exquisite collection of handles. Crafted with precision and attention to detail, our handles offer a perfect blend of durability and aesthetic appeal. Choose from a wide range of designs and finishes to suit your taste and enhance any space. From sleek and modern to classic and timeless, our handles add the perfect finishing touch to your home or office. Upgrade your doors and cabinets with our high-quality handles and transform your space today.


                </p>

              

                <div className='flex flex-row items-center mt-4 gap-12'>
                <Link to="/distributers" className=' text-white font-semibold py-3 cursor-pointer w-full md:w-1/3 bg-gradient-to-r from-amber-800 to-amber-500 text-center  rounded-xl h-full'>Our Vendors</Link>
                </div>
            </div>
          </div>

          <div className="col-span-full order-first md:order-last md:col-span-1 anim-product ">
          <div className='flex flex-col gap-2 md:gap-6 '>
                <img src={activeHandlesImg} alt="" className='w-full h-[20rem] md:h-[32rem] aspect-square object-cover rounded-xl'/>
                <div className='flex flex-row justify-between md:justify-evenly h-24'>
                    <img src={imagesVaastu.img1} alt="" className='w-20 md:w-24 h-20 md:h-24 rounded-md cursor-pointer' onClick={() => setActiveHandlesImage(imagesHandles.img1)}/>
                    <img src={imagesVaastu.img2} alt="" className='w-20 md:w-24 h-20 md:h-24 rounded-md cursor-pointer' onClick={() => setActiveHandlesImage(imagesHandles.img2)}/>
                    <img src={imagesHandles.img3} alt="" className='w-20 md:w-24 h-20 md:h-24 rounded-md cursor-pointer' onClick={() => setActiveHandlesImage(imagesVaastu.img3)}/>
                    <img src={imagesHandles.img4} alt="" className='w-20 md:w-24 h-20 md:h-24 rounded-md cursor-pointer' onClick={() => setActiveHandlesImage(imagesHandles.img4)}/>
                </div>
            </div>
          </div>
        </div>
         
        <div class="bg-gradient-to-br container  mx-4 flex justify-self-center md:my-6 mt-6   from-gray-500 to-gray-600 py-16 md:bg-gradient-to-r bg-cover " id="brands">
  <div class="container m-auto px-6 text-center md:px-12 lg:px-20">
    <h2 class="mb-8 text-4xl font-bold text-white md:text-4xl">
      Brands We Offer
    </h2>
    <div className="flex gap-6 justify-center flex-wrap">
<img src={Hafele} alt="" className='w-[8rem] h-[5rem] md:w-[10rem] md:h-[6rem] xl:w-[14rem] xl:h-[7rem] object-cover'/>
<img src={Sleek} alt="" className='w-[8rem] h-[5rem] md:w-[10rem] md:h-[6rem] xl:w-[14rem] xl:h-[7rem] object-cover'/>
<img src={Hettich} alt="" className='w-[8rem] h-[5rem] md:w-[10rem] md:h-[6rem] xl:w-[14rem] xl:h-[7rem] object-cover'/>
    </div>
  </div>
</div>


        </div>
  )
}

export default Hardware 