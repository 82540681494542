import React from 'react'

const Distributers = () => {
  return (
    <div className=" grid items-center bg-[#fffcf8] ">


<div className="block container px-4 py-12 mx-auto">
    <div className="Product-Sans bg-clip-text block  text-transparent bg-gradient-to-r from-amber-600 to-amber-500 text-2xl md:text-4xl  px-4 md:px-0 font-semibold">Our Distributors
</div>
<div className=" anim-product Product-Sans px-4 md:px-0 text-base md:text-lg font-medium mt-6">Lorem ipsum dolor sit amet consectetur adipisicing elit. Provident hic id eaque quos fugit nobis nisi nesciunt, pariatur animi quo!
</div>


{/* Vendor */}
<div className="grid grid-cols-3 px-4 md:px-0  py-10 justify-center container mx-auto gap-10">
 
          <div className="col-span-full w-full bg-white lg:col-span-1 p-4 bg-[#fcf4ea rounded-lg shadow-lg items-center animate-slide-fade-product">
          <div className='flex flex-col Product-Sans gap-4  '>
                <div>
                    {/* <span className='  bg-clip-text block font-semibold text-transparent bg-gradient-to-r from-amber-600 to-amber-500'>Premium Product</span> */}
                    <h1 className='text-xl font-bold'>Sikkim Vendors</h1>
                </div>
                <div className="flex-grow text-[#315FCA] py-1 text-base px-1 cursor-pointer">
                    <div className="flex  border-gray-200 py-2">
                      <span className="text-gray-500"><i class="ri-phone-fill pr-1 bg-clip-text  font-semibold text-transparent bg-gradient-to-r from-amber-600 to-amber-500"></i> Contact No.:</span>
                      <span className="ml-auto text-gray-900 ">+91 01234 56789</span>
                    </div>
                    <div className="flex border-t border-gray-200 py-2">
                      <span className="text-gray-500"><i class="ri-mail-fill pr-1 bg-clip-text  font-semibold text-transparent bg-gradient-to-r from-amber-600 to-amber-500"></i> Email:</span>
                      <span className="ml-auto text-gray-900">
                      name@mail.com
                      </span>
                    </div>
                    <div className="flex border-t border-gray-200 py-2">
                      <span className="text-gray-500"><i class="ri-map-pin-2-fill pr-1 bg-clip-text  font-semibold text-transparent bg-gradient-to-r from-amber-600 to-amber-500"></i> Address:</span>
                      <span className="ml-auto text-gray-900">
                        Somewhere in Sikkim
                      </span>
                    </div>
                    <div className="flex border-t  border-gray-200 py-2">
                      <span className="text-gray-500"><i class="ri-dropbox-fill pr-1 bg-clip-text  font-semibold text-transparent bg-gradient-to-r from-amber-600 to-amber-500"></i> Products:</span>
                      <span className="ml-auto text-gray-900">
                        Laminates, Louvers
                      </span>
                    </div>
                  </div>
               

            

                
            </div>
          </div>

          <div className="col-span-full w-full bg-white lg:col-span-1 p-4 bg-[#fcf4ea rounded-lg shadow-lg items-center animate-slide-fade-product">
          <div className='flex flex-col Product-Sans gap-4  '>
                <div>
                    {/* <span className='  bg-clip-text block font-semibold text-transparent bg-gradient-to-r from-amber-600 to-amber-500'>Premium Product</span> */}
                    <h1 className='text-xl font-bold'>Vendors 2 Name</h1>
                </div>
                <div className="flex-grow text-[#315FCA] py-1 text-base px-1 cursor-pointer">
                    <div className="flex  border-gray-200 py-2">
                      <span className="text-gray-500"><i class="ri-phone-fill pr-1 bg-clip-text  font-semibold text-transparent bg-gradient-to-r from-amber-600 to-amber-500"></i> Contact No.:</span>
                      <span className="ml-auto text-gray-900 ">+91 01234 56789</span>
                    </div>
                    <div className="flex border-t border-gray-200 py-2">
                      <span className="text-gray-500"><i class="ri-mail-fill pr-1 bg-clip-text  font-semibold text-transparent bg-gradient-to-r from-amber-600 to-amber-500"></i> Email:</span>
                      <span className="ml-auto text-gray-900">
                      name@mail.com
                      </span>
                    </div>
                    <div className="flex border-t border-gray-200 py-2">
                      <span className="text-gray-500"><i class="ri-map-pin-2-fill pr-1 bg-clip-text  font-semibold text-transparent bg-gradient-to-r from-amber-600 to-amber-500"></i> Address:</span>
                      <span className="ml-auto text-gray-900">
                        Somewhere in this world
                      </span>
                    </div>
                    <div className="flex border-t  border-gray-200 py-2">
                      <span className="text-gray-500"><i class="ri-dropbox-fill pr-1 bg-clip-text  font-semibold text-transparent bg-gradient-to-r from-amber-600 to-amber-500"></i> Products:</span>
                      <span className="ml-auto text-gray-900">
                        Laminates, Louvers
                      </span>
                    </div>
                  </div>
               

            

                
            </div>
          </div>
          <div className="col-span-full w-full bg-white lg:col-span-1 p-4 bg-[#fcf4ea rounded-lg shadow-lg items-center animate-slide-fade-product">
          <div className='flex flex-col Product-Sans gap-2  '>
                <div>
                    {/* <span className='  bg-clip-text block font-semibold text-transparent bg-gradient-to-r from-amber-600 to-amber-500'>Premium Product</span> */}
                    <h1 className='text-xl font-bold'>Vendor 3 Name</h1>
                </div>
                <div className="flex-grow text-[#315FCA] py-1 text-base px-1 cursor-pointer">
                    <div className="flex  border-gray-200 py-2">
                      <span className="text-gray-500"><i class="ri-phone-fill pr-1 bg-clip-text  font-semibold text-transparent bg-gradient-to-r from-amber-600 to-amber-500"></i> Contact No.:</span>
                      <span className="ml-auto text-gray-900 ">+91 01234 56789</span>
                    </div>
                    <div className="flex border-t border-gray-200 py-2">
                      <span className="text-gray-500"><i class="ri-mail-fill pr-1 bg-clip-text  font-semibold text-transparent bg-gradient-to-r from-amber-600 to-amber-500"></i> Email:</span>
                      <span className="ml-auto text-gray-900">
                      name@mail.com
                      </span>
                    </div>
                    <div className="flex border-t border-gray-200 py-2">
                      <span className="text-gray-500"><i class="ri-map-pin-2-fill pr-1 bg-clip-text  font-semibold text-transparent bg-gradient-to-r from-amber-600 to-amber-500"></i> Address:</span>
                      <span className="ml-auto text-gray-900">
                        Somewhere in this world
                      </span>
                    </div>
                    <div className="flex border-t  border-gray-200 py-2">
                      <span className="text-gray-500"><i class="ri-dropbox-fill pr-1 bg-clip-text  font-semibold text-transparent bg-gradient-to-r from-amber-600 to-amber-500"></i> Products:</span>
                      <span className="ml-auto text-gray-900">
                        Laminates, Louvers
                      </span>
                    </div>
                  </div>
               

            

                
            </div>
          </div>
          

          
        </div>





                                    

</div>
</div>
  )
}

export default Distributers