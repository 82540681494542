import React  from "react";
import { Link } from 'react-router-dom'

const Products = () => {





    const data = [
      {
        "name": "Plywood",
        "detail": "Jakarta Garden City Street, Cakung. Pulo Gadung, Jakarta Timur, DKI Jakarta",
        "image": require("../Images/plywood.jpg"),
        "link": "/plywood"
      },
      {
        "name": "Laminates ",
        "detail": "Pahlawan Street XVII No.215, Cinangka, Sawangan, Depok, Jawa Barat",
        "image": require("../Images/laminate.jpg"),
        "link": "/laminates"
      },
      {
        "name": "Acrylic",
        "detail": "Ruko Puri Indah Residence Block A7, Lingkar Street, Ciracas, Serang, Banten",
        "image": require("../Images/acrylic.jpg"),
        "link": "/acrylic"
      },
      {
        "name": "Louvers",
        "detail": "Ruko Puri Indah Residence Block A7, Lingkar Street, Ciracas, Serang, Banten",
        "image": require("../Images/louvers.jpg"),
        "link": "/louvers"
      },
      {
        "name": "Highlighters",
        "detail": "Ruko Puri Indah Residence Block A7, Lingkar Street, Ciracas, Serang, Banten",
        "image": require("../Images/highlighter.jpg"),
        "link": "/highlighters"
      }, {
        "name": "Veneers",
        "detail": "Ruko Puri Indah Residence Block A7, Lingkar Street, Ciracas, Serang, Banten",
        "image": require("../Images/veneer.jpg"),
        "link": "/veneers"
      }, {
        "name": "Wooden Floorings",
        "detail": "Ruko Puri Indah Residence Block A7, Lingkar Street, Ciracas, Serang, Banten",
        "image": require("../Images/wooden.jpg"),
        "link": "/wooden-flooring"
      }, {
        "name": "Hardware Fittings",
        "detail": "Ruko Puri Indah Residence Block A7, Lingkar Street, Ciracas, Serang, Banten",
        "image": require("../Images/hardware.jpg"),
        "link": "/hardware"
      }, {
        "name": "Other Decoratives",
        "detail": "Ruko Puri Indah Residence Block A7, Lingkar Street, Ciracas, Serang, Banten",
        "image": require("../Images/molding.jpg"),
        "link": "/decoratives"
      },]
    
  return (
    <div>
        
        
        <div className=" grid items-center ">
      <div className="bg-gray-800">
      <div className=" container mx-auto">
<nav aria-label="breadcrumb" className="w- p-4  bg-gray-800 text-gray-100">
	<ol className="flex h-8 space-x-2">
		<li className="flex items-center">
    <Link  to="/home" title="Back to homepage" className="hover:no-underline">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5 pr-1 dark:text-gray-400">
					<path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path>
				</svg>
			</Link>
		</li>
		<li className="flex items-center space-x-2">
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" aria-hidden="true" fill="currentColor" className="w-2 h-2 mt-1 transform rotate-90 fill-current dark:text-gray-600">
				<path d="M32 30.031h-32l16-28.061z"></path>
			</svg>
			<Link  to="/products" className="flex items-center px-1 capitalize hover:no-underline">Products</Link>
		</li>
	
		
	</ol>
</nav>
</div>
</div>



<div className="block container px-4 pb-12 mx-auto">
<div className="text-4xl text-center flex flex-col Product-Sans my-10 font-bold">
<span className="text-2xl text-center  Product-Sans  font-semibold bg-clip-text block  text-transparent bg-gradient-to-r from-amber-600 to-amber-500">
Exclusive Collections </span>
Our Products

</div>


          <div className='grid grid-cols-3 items-center gap-6 mt-4 mb-8'>
      
      {data.map((card, i)=>(
        // <div >
          //  <div className="flex flex-col max-w-[16rem] m-auto text-left  gap-2 transition-all duration-300 ease-in-out hover:scale-105 hover:cursor-pointer bg-gradient-to-b from-custom-gradient1 to-custom-gradient2 p-4 hover:shadow-xl hover:shadow-blue-200 rounded-2xl" > 
          <Link key={i} to={card.link} className="flex flex-col col-span-full md:col-span-1">
          <div className="flex flex-col col-span-full md:col-span-1 max-w-[26rem] md:max-w-56 m-auto text-left w-full md:w-full gap-2 transition-all duration-300 ease-in-out hover:scale-105 hover:cursor-pointer bg-gradient-to-b from-custom-gradient3 to-custom-gradient4  p-4 hover:shadow-lg hover:shadow-orange-200 rounded-2xl" >
            <img src={card.image} alt="Product" className='rounded-2xl w-[100%] max-w- h-[18rem] object-cover '  />
           <div className="text-[1.5rem] font-bold Product-Sans tracking-wide">
           {card.name}
           </div>
           <span className='text-sm w-full Product-Sans text-gray-600'>Lorem, ipsum dolor consectetur sit amet adipisicing elit. </span>
           <div className="text-[1rem] font- Product-Sans text-white tracking-wide">
           {/* <button className="bg-stone-800 w-full py-2   rounded-xl"> Know more </button> */}
           </div>
          </div>
          </Link>
        // </div>
      ))}
</div>

</div>
   
</div>
        
    </div>
  )
}

export default Products