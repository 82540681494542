
 const data = [
  {
    "name": "Plywood",
    "detail": "Jakarta Garden City Street, Cakung. Pulo Gadung, Jakarta Timur, DKI Jakarta",
    "image": require("../Images/plywood.jpg"),
    "link": "/plywood"
  },
  {
    "name": "Laminates ",
    "detail": "Pahlawan Street XVII No.215, Cinangka, Sawangan, Depok, Jawa Barat",
    "image": require("../Images/laminate.jpg"),
    "link": "/laminates"
  },
  {
    "name": "Acrylic",
    "detail": "Ruko Puri Indah Residence Block A7, Lingkar Street, Ciracas, Serang, Banten",
    "image": require("../Images/acrylic.jpg"),
    "link": "/acrylic"
  },
  {
    "name": "Louvers",
    "detail": "Ruko Puri Indah Residence Block A7, Lingkar Street, Ciracas, Serang, Banten",
    "image": require("../Images/louvers.jpg"),
    "link": "/louvers"
  },
  {
    "name": "Highlighters",
    "detail": "Ruko Puri Indah Residence Block A7, Lingkar Street, Ciracas, Serang, Banten",
    "image": require("../Images/highlighter.jpg"),
    "link": "/highlighters"
  }, {
    "name": "Veneers",
    "detail": "Ruko Puri Indah Residence Block A7, Lingkar Street, Ciracas, Serang, Banten",
    "image": require("../Images/veneer.jpg"),
    "link": "/veneers"
  }, {
    "name": "Wooden Floorings",
    "detail": "Ruko Puri Indah Residence Block A7, Lingkar Street, Ciracas, Serang, Banten",
    "image": require("../Images/wooden.jpg"),
    "link": "/wooden-flooring"
  }, {
    "name": "Hardware Fittings",
    "detail": "Ruko Puri Indah Residence Block A7, Lingkar Street, Ciracas, Serang, Banten",
    "image": require("../Images/hardware.jpg"),
    "link": "/hardware"
  }, {
    "name": "Other Decoratives",
    "detail": "Ruko Puri Indah Residence Block A7, Lingkar Street, Ciracas, Serang, Banten",
    "image": require("../Images/molding.jpg"),
    "link": "/decoratives"
  },]

  export default data 