
import React, { useState } from "react";

import Greenlam from "../Images/Greenlam.png"
import Merino from "../Images/Merino.jpg"
import Heritage from "../Images/Heritage.jpg"
import Centurylam from "../Images/Centurylam.jpg"
import Skydecor from "../Images/Skydecor.jpg"
import Meraki from "../Images/Meraki.jpg"
import { Link } from "react-router-dom";


const Laminates = () => {

  const [isOpen, setIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const openModal = (image) => {
    setSelectedImage(image);
    setIsOpen(true);
  };

  const closeModal = () => {
    setSelectedImage(null);
    setIsOpen(false);
  };

    const data = [
        {
          "name": "MELIA PRIME TOUCH",
          "detail": "Jakarta Garden City Street, Cakung. Pulo Gadung, Jakarta Timur, DKI Jakarta",
          "image": require("../Images/img_1.jpg")
        },
        {
          "name": "PROMICA ",
          "detail": "Pahlawan Street XVII No.215, Cinangka, Sawangan, Depok, Jawa Barat",
          "image": require("../Images/img_2.jpg")
        },
        {
          "name": "DAYA",
          "detail": "Ruko Puri Indah Residence Block A7, Lingkar Street, Ciracas, Serang, Banten",
          "image": require("../Images/img_3.jpg")
        },]




        
 //Mica
 const [imagesMica, setImagesMica] = useState({
  img1 : require("../Images/ply.jpg"),
    img2 : require("../Images/ply2.jpg"),
    img3 : require("../Images/ply3.jpg"),
    img4 : require("../Images/ply.jpg"),
})

const [activeMicaImg, setActiveMicaImage] = useState(imagesMica.img1)

//Glass Mica
const [imagesGlass, setimagesGlass] = useState({
  img1 : require("../Images/ply.jpg"),
  img2 : require("../Images/ply2.jpg"),
  img3 : require("../Images/ply3.jpg"),
  img4 : require("../Images/ply.jpg"),
})

const [activeGlassImg, setActiveGlassImage] = useState(imagesGlass.img1)

//Saburi
const [imagesUnicore, setimagesUnicore] = useState({
  img1 : require("../Images/ply.jpg"),
  img2 : require("../Images/ply2.jpg"),
  img3 : require("../Images/ply3.jpg"),
  img4 : require("../Images/ply.jpg"),
})

const [activeUnicoreImg, setActiveUnicoreImage] = useState(imagesUnicore.img1)



  return (
//     <div className=" grid items-center ">
//       <div className="bg-gray-800">
//       <div className=" container mx-auto">
// <nav aria-label="breadcrumb" className="w- p-4  bg-gray-800 text-gray-100">
// 	<ol className="flex h-8 space-x-2">
// 		<li className="flex items-center">
// 			<a rel="noopener noreferrer" href="#" title="Back to homepage" className="hover:no-underline">
// 				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5 pr-1 dark:text-gray-400">
// 					<path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path>
// 				</svg>
// 			</a>
// 		</li>
// 		<li className="flex items-center space-x-2">
// 			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" aria-hidden="true" fill="currentColor" className="w-2 h-2 mt-1 transform rotate-90 fill-current dark:text-gray-600">
// 				<path d="M32 30.031h-32l16-28.061z"></path>
// 			</svg>
// 			<a rel="noopener noreferrer" href="#" className="flex items-center px-1 capitalize hover:no-underline">Products</a>
// 		</li>
// 		<li className="flex items-center space-x-2">
// 			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" aria-hidden="true" fill="currentColor" className="w-2 h-2 mt-1 transform rotate-90 fill-current dark:text-gray-600">
// 				<path d="M32 30.031h-32l16-28.061z"></path>
// 			</svg>
// 			<a rel="noopener noreferrer" href="#" className="flex items-center px-1 capitalize hover:no-underline">Laminates</a>
// 		</li>
		
// 	</ol>
// </nav>
// </div>
// </div>



// <div className="block container px-4 py-12 mx-auto">
// <div className=" anim-product Product-Sans px-2 md:px-0 text-base md:text-lg font-medium">We are your premier distributor of the finest quality laminates in Siliguri, Sikkim, and West Bengal. As trusted distributors, we offer the highest quality laminates for your exquisite interiors. Elevate your spaces with our wide range of designs and exceptional durability. Experience the perfect blend of style and functionality today!</div>

//           {/* <div className='grid grid-cols-3 items-center my-8'>
      
//       {data.map((card, i)=>(
//         // <div >
//           //  <div className="flex flex-col max-w-[16rem] m-auto text-left  gap-2 transition-all duration-300 ease-in-out hover:scale-105 hover:cursor-pointer bg-gradient-to-b from-custom-gradient1 to-custom-gradient2 p-4 hover:shadow-xl hover:shadow-blue-200 rounded-2xl" > 
//           <div key={i}className="flex flex-col col-span-full md:col-span-1 max-w-[26rem] md:max-w-56 m-auto text-left w-full md:w-full gap-2 transition-all duration-300 ease-in-out hover:scale-105 hover:cursor-pointer bg-gradient-to-b from-custom-gradient3 to-custom-gradient4  p-4 hover:shadow-lg hover:shadow-orange-200 rounded-2xl" >
//             <img src={card.image} alt="Product" className='rounded-2xl w-[100%] max-w- h-[18rem] object-cover '  onClick={() => openModal(card.image)} />
//            <div className="text-[1.5rem] font-bold Product-Sans tracking-wide">
//            {card.name}
//            </div>
//            <span className='text-sm w-full Product-Sans text-gray-600'>Lorem, ipsum dolor consectetur sit amet adipisicing elit. </span>
//            <div className="text-[1rem] font- Product-Sans text-white tracking-wide">
//            <button className="bg-stone-800 w-full py-2   rounded-xl"><i class="ri-download-2-line pr-2"></i> <span>Brochure</span> </button>
//            </div>
//           </div>
//         // </div>
//       ))}
// </div> */}

// <div className=" anim-product px-2 md:px-0 Product-Sans mt-4 text-base md:text-lg  font-medium">We have no scarcity of other spectacular laminate brands in our store such as Greenlam, Merino, Heritage, Century, Skydecor, Meraki, etc.</div>
// </div>
//        {isOpen && (
//         <div className="fixed top-0 left-0 right-0 bottom-0 flex justify-center items-center">
//           <div
//             className="fixed top-0 left-0 right-0 bottom-0 bg-black bg-opacity-75 backdrop-blur-md"
            
//           ></div>
//           <div className="relative z-10  bg-white rounded-lg">
//             <button
//               className="absolute top-2 right-2 text-gray-600"
//               onClick={closeModal}
//             >
//               {/* Close Icon */}
//               <svg
//                 xmlns="http://www.w3.org/2000/svg"
//                 className="h-6 w-6"
//                 fill="none"
//                 viewBox="0 0 24 24"
//                 stroke="currentColor"
//               >
//                 <path
//                   strokeLinecap="round"
//                   strokeLinejoin="round"
//                   strokeWidth="2"
//                   d="M6 18L18 6M6 6l12 12"
//                 />
//               </svg>
//             </button>
//             <img
//               src={selectedImage}
//               alt="Enlarged Image"
//               className="max-w-[80vw] max-h-[80vh] z-2"
//             />
//           </div>
//         </div>
//       )}
// </div>



<div className=" grid items-center ">
<div className="bg-gray-800">
<div className=" container mx-auto">
<nav aria-label="breadcrumb" className="w- p-4  bg-gray-800 text-gray-100">
<ol className="flex h-8 space-x-2">
  <li className="flex items-center">
  <Link  to="/home" title="Back to homepage" className="hover:no-underline">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5 pr-1 dark:text-gray-400">
              <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path>
          </svg>
      </Link>
  </li>
  <li className="flex items-center space-x-2">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" aria-hidden="true" fill="currentColor" className="w-2 h-2 mt-1 transform rotate-90 fill-current dark:text-gray-600">
          <path d="M32 30.031h-32l16-28.061z"></path>
      </svg>
      <Link  to="/products" className="flex items-center px-1 capitalize hover:no-underline">Products</Link>
  </li>
  <li className="flex items-center space-x-2">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" aria-hidden="true" fill="currentColor" className="w-2 h-2 mt-1 transform rotate-90 fill-current dark:text-gray-600">
          <path d="M32 30.031h-32l16-28.061z"></path>
      </svg>
      <Link  to="/laminates" className="flex items-center px-1 capitalize hover:no-underline">Laminates</Link>
  </li>
  
</ol>
</nav>
</div>
</div>



<div className="block container px-4 py-12 mx-auto">
<div className="Product-Sans bg-clip-text block  text-transparent bg-gradient-to-r from-amber-600 to-amber-500 text-2xl md:text-4xl  px-4 md:px-0 font-semibold">High-quality laminates for all your creative needs
</div>
<div className=" anim-product Product-Sans px-4 md:px-0 text-base md:text-lg font-medium mt-6">We are your premier distributor of the finest quality laminates in Siliguri, Sikkim, and West Bengal. As trusted distributors, we offer the highest quality laminates for your exquisite interiors. Elevate your spaces with our wide range of designs and exceptional durability. Experience the perfect blend of style and functionality today!

</div>


{/* Mica */}
<div className="grid grid-cols-2  px-4 md:px-0  py-10 justify-center container mx-auto gap-10">
      <div className="col-span-full md:col-span-1 anim-product">
      <div className='flex flex-col gap-2 md:gap-6 '>
            <img src={activeMicaImg} alt="" className='w-full h-[20rem] md:h-[32rem] aspect-square object-cover rounded-xl'/>
            <div className='flex flex-row justify-between md:justify-evenly h-24'>
                <img src={imagesMica.img1} alt="" className='w-20 md:w-24 h-20 md:h-24 rounded-md cursor-pointer' onClick={() => setActiveMicaImage(imagesMica.img1)}/>
                <img src={imagesMica.img2} alt="" className='w-20 md:w-24 h-20 md:h-24 rounded-md cursor-pointer' onClick={() => setActiveMicaImage(imagesMica.img2)}/>
                <img src={imagesMica.img3} alt="" className='w-20 md:w-24 h-20 md:h-24 rounded-md cursor-pointer' onClick={() => setActiveMicaImage(imagesMica.img3)}/>
                <img src={imagesMica.img4} alt="" className='w-20 md:w-24 h-20 md:h-24 rounded-md cursor-pointer' onClick={() => setActiveMicaImage(imagesMica.img4)}/>
            </div>
        </div>
      </div>
      <div className="col-span-full md:col-span-1 flex items-center animate-slide-fade-product">
      <div className='flex flex-col Product-Sans gap-4 '>
            <div>
                <span className='  bg-clip-text block font-semibold text-transparent bg-gradient-to-r from-amber-600 to-amber-500'>Premium Product</span>
                <h1 className='text-3xl font-bold'>Melia Prime Touch</h1>
            </div>
            <p className='text-gray-600 md:text-base xl:text-lg  font-medium'>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Culpa, veniam sequi. Quae iure quibusdam error facilis illum quaerat hic ipsum cupiditate, praesentium ut. A, et optio.  
            </p>

        

            <div className='flex flex-col md:flex-row items-center  mt-4 gap-6'>
                <button className=' text-white font-semibold py-3  w-full md:w-1/3 bg-gradient-to-r from-amber-800 to-amber-500 text-center cursor-pointer rounded-xl h-full'> <i class="ri-download-2-line pr-2"></i> <span>Brochure</span></button>
                <Link to="/distributers" className='cursor-pointer'>Our Vendors</Link>
                
            </div>
        </div>
      </div>
    </div>

{/* Glass Mica */}
    <div className="grid grid-cols-2 px-4 md:px-0  py-10 justify-center container mx-auto gap-10 ">
    <div className="col-span-full order-last md:order-first md:col-span-1 flex items-center animate-slide-fade-product">
      <div className='flex flex-col Product-Sans gap-4 '>
            <div>
                <span className=' text-violet-60 bg-clip-text block font-semibold text-transparent bg-gradient-to-r from-amber-600 to-amber-500'>Premium Product</span>
                <h1 className='text-3xl font-bold'>Promica</h1>
            </div>
            <p className='text-gray-600 md:text-base xl:text-lg  font-medium'>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Culpa, veniam sequi. Quae iure quibusdam error facilis illum quaerat hic ipsum cupiditate, praesentium ut. A, et optio.

            </p>

    

            <div className='flex flex-col md:flex-row items-center  mt-4 gap-6'>
                <button className=' text-white font-semibold py-3  w-full md:w-1/3 bg-gradient-to-r from-amber-800 to-amber-500 text-center cursor-pointer rounded-xl h-full'> <i class="ri-download-2-line pr-2"></i> <span>Brochure</span></button>
                <Link to="/distributers" className='cursor-pointer'>Our Vendors</Link>
            </div>
        </div>
      </div>

      <div className="col-span-full order-first md:order-last md:col-span-1 anim-product ">
      <div className='flex flex-col gap-2 md:gap-6 '>
            <img src={activeGlassImg} alt="" className='w-full h-[20rem] md:h-[32rem] aspect-square object-cover rounded-xl'/>
            <div className='flex flex-row justify-between md:justify-evenly h-24'>
                <img src={imagesGlass.img1} alt="" className='w-20 md:w-24 h-20 md:h-24 rounded-md cursor-pointer' onClick={() => setActiveGlassImage(imagesGlass.img1)}/>
                <img src={imagesGlass.img2} alt="" className='w-20 md:w-24 h-20 md:h-24 rounded-md cursor-pointer' onClick={() => setActiveGlassImage(imagesGlass.img2)}/>
                <img src={imagesGlass.img3} alt="" className='w-20 md:w-24 h-20 md:h-24 rounded-md cursor-pointer' onClick={() => setActiveGlassImage(imagesGlass.img3)}/>
                <img src={imagesGlass.img4} alt="" className='w-20 md:w-24 h-20 md:h-24 rounded-md cursor-pointer' onClick={() => setActiveGlassImage(imagesGlass.img4)}/>
            </div>
        </div>
      </div>
     
    </div>

{/* Unicore Acrylic Mica */}
<div className="grid grid-cols-2  px-4 md:px-0  py-10 justify-center container mx-auto gap-10">
      <div className="col-span-full md:col-span-1 anim-product">
      <div className='flex flex-col gap-2 md:gap-6 '>
            <img src={activeUnicoreImg} alt="" className='w-full h-[20rem] md:h-[32rem] aspect-square object-cover rounded-xl'/>
            <div className='flex flex-row justify-between md:justify-evenly h-24'>
                <img src={imagesUnicore.img1} alt="" className='w-20 md:w-24 h-20 md:h-24 rounded-md cursor-pointer' onClick={() => setActiveUnicoreImage(imagesUnicore.img1)}/>
                <img src={imagesUnicore.img2} alt="" className='w-20 md:w-24 h-20 md:h-24 rounded-md cursor-pointer' onClick={() => setActiveUnicoreImage(imagesUnicore.img2)}/>
                <img src={imagesUnicore.img3} alt="" className='w-20 md:w-24 h-20 md:h-24 rounded-md cursor-pointer' onClick={() => setActiveUnicoreImage(imagesUnicore.img3)}/>
                <img src={imagesUnicore.img4} alt="" className='w-20 md:w-24 h-20 md:h-24 rounded-md cursor-pointer' onClick={() => setActiveUnicoreImage(imagesUnicore.img4)}/>
            </div>
        </div>
      </div>
      <div className="col-span-full md:col-span-1 flex items-center animate-slide-fade-product">
      <div className='flex flex-col Product-Sans gap-4 '>
            <div>
                <span className='  bg-clip-text block font-semibold text-transparent bg-gradient-to-r from-amber-600 to-amber-500'>Premium Product</span>
                <h1 className='text-3xl font-bold'>Daya</h1>
            </div>
            <p className='text-gray-600 md:text-base xl:text-lg  font-medium'>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Culpa, veniam sequi. Quae iure quibusdam error facilis illum quaerat hic ipsum cupiditate, praesentium ut. A, et optio.

            </p>

    

            <div className='flex flex-col md:flex-row items-center  mt-4 gap-6'>
                <button className=' text-white font-semibold py-3  w-full md:w-1/3 bg-gradient-to-r from-amber-800 to-amber-500 text-center cursor-pointer rounded-xl h-full'> <i class="ri-download-2-line pr-2"></i> <span>Brochure</span></button>
                <Link to="/distributers" className='cursor-pointer'>Our Vendors</Link>
            </div>
        </div>
      </div>
    </div>

    <div class="bg-gradient-to-br from-gray-500 to-gray-600 py-16 md:bg-gradient-to-r bg-cover z-1" id="brands">
<div class="container m-auto px-6 text-center md:px-12 lg:px-20">
<h2 class="mb-8 text-4xl font-bold text-white md:text-4xl">
  Brands We Offer
</h2>
<div className="flex gap-6 justify-center flex-wrap">
<img src={Greenlam} alt="" className='w-[8rem] h-[5rem] md:w-[10rem] md:h-[6rem] xl:w-[14rem] xl:h-[7rem] object-cover'/>
<img src={Merino} alt="" className='w-[8rem] h-[5rem] md:w-[10rem] md:h-[6rem] xl:w-[14rem] xl:h-[7rem] object-cover'/>
<img src={Heritage} alt="" className='w-[8rem] h-[5rem] md:w-[10rem] md:h-[6rem] xl:w-[14rem] xl:h-[7rem] object-cover'/>
<img src={Centurylam} alt="" className='w-[8rem] h-[5rem] md:w-[10rem] md:h-[6rem] xl:w-[14rem] xl:h-[7rem] object-cover'/>
<img src={Skydecor} alt="" className='w-[8rem] h-[5rem] md:w-[10rem] md:h-[6rem] xl:w-[14rem] xl:h-[7rem] object-cover'/>
<img src={Meraki} alt="" className='w-[8rem] h-[5rem] md:w-[10rem] md:h-[6rem] xl:w-[14rem] xl:h-[7rem] object-cover'/>
</div>
</div>
</div>

                                

</div>
</div>
  )
}

export default Laminates