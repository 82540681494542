import React from 'react'
import { Link } from 'react-router-dom'

const Maintenance = () => {
  return (
    <div>
              <nav aria-label="breadcrumb" className="w-full p-4 bg-red-100 text-gray-800 Product-Sans flex justify-center">
	<ol className="flex h-8 space-x-2 container  ">
		<Link to="/" className="flex items-center">
			<a rel="noopener noreferrer" href="#" title="Back to homepage" className="hover:underline">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5 pr-1 text-gray-800">
					<path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path>
				</svg>
			</a>
		</Link>
		<li className="flex items-center space-x-2">
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" aria-hidden="true" fill="currentColor" className="w-2 h-2 mt-1 transform rotate-90 fill-current text-gray-600">
				<path d="M32 30.031h-32l16-28.061z"></path>
			</svg>
			<a rel="noopener noreferrer" href="#" className="flex items-center px-1 capitalize hover:no-underline">Services</a>
		</li>
		
		<li className="flex items-center space-x-2">
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" aria-hidden="true" fill="currentColor" className="w-2 h-2 mt-1 transform rotate-90 fill-current text-gray-600">
				<path d="M32 30.031h-32l16-28.061z"></path>
			</svg>
			<a rel="noopener noreferrer" href="#" className="flex items-center px-1 capitalize hover:no-underline cursor-default">Maintenance</a>
		</li>
	</ol>
</nav>


<div class="container p-6 px-6 mx-auto bg-white ">
    <div class="m-16 text-center">
        <h2 class="text-base font-semibold tracking-wide text-red-600 uppercase">
        Continuity in Excellence
        </h2>
        <p class="mt-2 text-3xl font-semibold leading-8 tracking-tight text-gray-900  sm:text-4xl">
        Empowering Your Digital Realm with Expert Maintenance Solutions
        </p>
    </div>
    <div class="flex flex-wrap my-12 ">
        <div class="w-full p-8 border-b md:w-1/2 md:border-r lg:w-1/3">
            <div class="flex items-center mb-6">
                <svg width="20" height="20" fill="currentColor" class="w-6 h-6 text-red-500" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                    <path d="M491 1536l91-91-235-235-91 91v107h128v128h107zm523-928q0-22-22-22-10 0-17 7l-542 542q-7 7-7 17 0 22 22 22 10 0 17-7l542-542q7-7 7-17zm-54-192l416 416-832 832h-416v-416zm683 96q0 53-37 90l-166 166-416-416 166-165q36-38 90-38 53 0 91 38l235 234q37 39 37 91z">
                    </path>
                </svg>
                <div class="ml-4 text-xl">
                Reliable Performance
                </div>
            </div>
            <p class="leading-loose text-gray-500  text-md">
            Regular upkeep ensures your website functions flawlessly, providing visitors with a smooth and dependable user experience.
            </p>
        </div>
        <div class="w-full p-8 border-b md:w-1/2 lg:w-1/3 lg:border-r">
            <div class="flex items-center mb-6">
                <svg width="20" height="20" fill="currentColor" class="w-6 h-6 text-red-500" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                    <path d="M491 1536l91-91-235-235-91 91v107h128v128h107zm523-928q0-22-22-22-10 0-17 7l-542 542q-7 7-7 17 0 22 22 22 10 0 17-7l542-542q7-7 7-17zm-54-192l416 416-832 832h-416v-416zm683 96q0 53-37 90l-166 166-416-416 166-165q36-38 90-38 53 0 91 38l235 234q37 39 37 91z">
                    </path>
                </svg>
                <div class="ml-4 text-xl">
                Security Reinforcement
                </div>
            </div>
            <p class="leading-loose text-gray-500  text-md">
            Continuous monitoring and updates shield your website against potential vulnerabilities and cyber threats, safeguarding sensitive data.
            </p>
        </div>
        <div class="w-full p-8 border-b md:w-1/2 md:border-r lg:w-1/3 lg:border-r-0">
            <div class="flex items-center mb-6">
                <svg width="20" height="20" fill="currentColor" class="w-6 h-6 text-red-500" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                    <path d="M491 1536l91-91-235-235-91 91v107h128v128h107zm523-928q0-22-22-22-10 0-17 7l-542 542q-7 7-7 17 0 22 22 22 10 0 17-7l542-542q7-7 7-17zm-54-192l416 416-832 832h-416v-416zm683 96q0 53-37 90l-166 166-416-416 166-165q36-38 90-38 53 0 91 38l235 234q37 39 37 91z">
                    </path>
                </svg>
                <div class="ml-4 text-xl">
                Fresh and Relevant Content
                </div>
            </div>
            <p class="leading-loose text-gray-500  text-md">
            Scheduled updates keep your website's content current, engaging visitors and maintaining a vibrant online presence.
            </p>
        </div>
        <div class="w-full p-8 border-b md:w-1/2 lg:w-1/3 lg:border-r lg:border-b-0">
            <div class="flex items-center mb-6">
                <svg width="20" height="20" fill="currentColor" class="w-6 h-6 text-red-500" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                    <path d="M491 1536l91-91-235-235-91 91v107h128v128h107zm523-928q0-22-22-22-10 0-17 7l-542 542q-7 7-7 17 0 22 22 22 10 0 17-7l542-542q7-7 7-17zm-54-192l416 416-832 832h-416v-416zm683 96q0 53-37 90l-166 166-416-416 166-165q36-38 90-38 53 0 91 38l235 234q37 39 37 91z">
                    </path>
                </svg>
                <div class="ml-4 text-xl">
                Mobile Responsiveness
                </div>
            </div>
            <p class="leading-loose text-gray-500  text-md">
            Ensuring your website is mobile-friendly guarantees accessibility across various devices, accommodating a diverse range of users.
            </p>
        </div>
        <div class="w-full p-8 border-b md:w-1/2 md:border-r md:border-b-0 lg:w-1/3 lg:border-b-0">
            <div class="flex items-center mb-6">
                <svg width="20" height="20" fill="currentColor" class="w-6 h-6 text-red-500" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                    <path d="M491 1536l91-91-235-235-91 91v107h128v128h107zm523-928q0-22-22-22-10 0-17 7l-542 542q-7 7-7 17 0 22 22 22 10 0 17-7l542-542q7-7 7-17zm-54-192l416 416-832 832h-416v-416zm683 96q0 53-37 90l-166 166-416-416 166-165q36-38 90-38 53 0 91 38l235 234q37 39 37 91z">
                    </path>
                </svg>
                <div class="ml-4 text-xl">
                Efficient Backup and Recovery
                </div>
            </div>
            <p class="leading-loose text-gray-500  text-md">
            Regular data backups provide a safety net, allowing quick recovery in case of data loss or unforeseen events.
            </p>
        </div>
        <div class="w-full p-8 md:w-1/2 lg:w-1/3">
            <div class="flex items-center mb-6">
                <svg width="20" height="20" fill="currentColor" class="w-6 h-6 text-red-500" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                    <path d="M491 1536l91-91-235-235-91 91v107h128v128h107zm523-928q0-22-22-22-10 0-17 7l-542 542q-7 7-7 17 0 22 22 22 10 0 17-7l542-542q7-7 7-17zm-54-192l416 416-832 832h-416v-416zm683 96q0 53-37 90l-166 166-416-416 166-165q36-38 90-38 53 0 91 38l235 234q37 39 37 91z">
                    </path>
                </svg>
                <div class="ml-4 text-xl">
                Reduced Downtime
                </div>
            </div>
            <p class="leading-loose text-gray-500  text-md">
            Proactive monitoring and swift issue resolution minimize downtime, preserving your website's availability and reliability.
            </p>
        </div>
    </div>
</div>
    </div>
  )
}

export default Maintenance