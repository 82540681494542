import React from 'react'
import dev from "../Images/dev.svg"
import pen from "../Images/pen.svg"
import va from "../Images/va.svg"
import server from "../Images/server.svg"
import search from "../Images/search.svg"






const ServicesCard = () => {
  return (
    
   
    <div class="py-">
    <div class="container m-auto px-6 text-gray-500 md:px-12 xl:px-0">
      <div class="mx-auto grid gap-6 md:w-3/4 lg:w-full md:grid-cols-3 xl:grid-cols-5">
        <div class="border border-gray-100 rounded-3xl bg-white  px-8 py-12 lg:py-8 xxl:py-12 shadow-2xl shadow-red-600/10  sm:px-12 lg:px-8">
          <div class="mb-12 space-y-4">
            <h3 class="text-2xl md:text-xl xxl:text-2xl font-semibold text-gray-800 ">Web Development</h3>
            <p class="mb-6 text-gray-600 md:text-sm xxl:text-base ">
            Transform ideas into captivating digital experiences. Craft user-friendly websites/apps that captivate and convert. Elevate your online presence with us.
            </p>
            <a href="#" class="block font-medium text-primary">Know more</a>
          </div>
          <img
            src={dev}
            class="ml-auto w-2/3"
            alt="illustration"
            loading="lazy"
            width="900"
            height="600"
          />
        </div>
        <div class="border border-gray-100  rounded-3xl bg-white  px-8 py-12 lg:py-8 xxl:py-12 shadow-2xl shadow-red-600/10  sm:px-12 lg:px-8">
          <div class="mb-12 space-y-4">
            <h3 class="text-2xl md:text-xl xxl:text-2xl font-semibold text-gray-800 ">Graphic Design</h3>
            <p class="mb-6 text-gray-600 md:text-sm xxl:text-base">
            Our design service turns visions into striking visuals. From logos to branding and beyond, we craft stunning designs that resonate. Enhance your aesthetics with us.
            </p>
            <a href="#" class="block font-medium text-primary">Know more</a>
          </div>
          <img
            src={pen}
            class="ml-auto w-2/3"
            alt="illustration"
            loading="lazy"
            width="900"
            height="600"
          />
        </div>
        <div class="border border-gray-100  rounded-3xl bg-white  px-8 py-12 lg:py-8 xxl:py-12 shadow-2xl shadow-red-600/10  sm:px-12 lg:px-8">
          <div class="mb-12 space-y-4">
            <h3 class="text-2xl md:text-xl xxl:text-2xl font-semibold text-gray-800 ">Virtual Assistant</h3>
            <p class="mb-6 text-gray-600 md:text-sm xxl:text-base ">
            Boost productivity with virtual assistant service. We simplify admin tasks and organization, ensuring streamlined and efficient operations for your business.
            </p>
            <a href="#" class="block font-medium text-primary">Know more</a>
          </div>
          <img
            src={va}
            class="ml-auto w-2/3"
            alt="illustration"
            loading="lazy"
            width="900"
            height="600"
          />
        </div>
        <div class="border border-gray-100  rounded-3xl bg-white  px-8 py-12 lg:py-8 xxl:py-12 shadow-2xl shadow-red-600/10  sm:px-12 lg:px-8">
          <div class="mb-12 space-y-4">
            <h3 class="text-2xl md:text-xl xxl:text-2xl font-semibold text-gray-800 ">Maintenance</h3>
            <p class="mb-6 text-gray-600 md:text-sm xxl:text-base">
            Upkeep for your digital presence. We handle updates, security, and optimization, ensuring your website runs flawlessly. Your online success is our priority.
            </p>
            <a href="#" class="block font-medium text-primary">Know more</a>
          </div>
          <img
            src={server}
            class="ml-auto w-2/3"
            alt="illustration"
            loading="lazy"
            width="900"
            height="600"
          />
        </div>
        <div class="border border-gray-100  rounded-3xl bg-white  px-8 py-12 lg:py-8 xxl:py-12 shadow-2xl shadow-red-600/10  sm:px-12 lg:px-8">
          <div class="mb-12 space-y-4">
            <h3 class="text-2xl md:text-xl xxl:text-2xl font-semibold text-gray-800 ">SEO</h3>
            <p class="mb-6 text-gray-600 md:text-sm xxl:text-base ">
            Unleash online potential with our SEO. Enhance visibility, drive traffic, and boost rankings. Elevate your digital presence through strategic optimization.
            </p>
            <a href="#" class="block font-medium text-primary">Know more</a>
          </div>
          <img
            src={search}
            class="ml-auto w-2/3"
            alt="illustration"
            loading="lazy"
            width="900"
            height="600"
          />
        </div>
      </div>
    </div>

    
  </div>
                                      
  )
}

export default ServicesCard;

