import React, { useEffect, useState } from 'react';

const CountUp = ({ target, duration }) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    let start = 0;
    const increment = target / (duration / 10); // Divide duration by 10 to simulate 100ms interval

    const interval = setInterval(() => {
      start += increment;
      setCount(Math.min(Math.round(start), target));

      if (count >= target) {
        clearInterval(interval);
      }
    }, 100);

    return () => clearInterval(interval);
  }, [target, duration]);

  return <span>{count}</span>;
};

export default CountUp;
