import React, {useState} from "react";
import { BsFillCaretRightFill } from 'react-icons/bs';


const Accordion = (props) => {
  
    const [data, setData] = useState(props.datas);
    
    const handleToggleActive = () => {
        let activeNew = data.active === 1 ? 0 : 1;
        setData({...data, active : activeNew});
    }

    return (

        
                
            <div className={`w-full p-4  border border-[#aaaaaa55] rounded-md mb-5  duration-500 group ${data.active === 1 ? 'is-active bg-white shadow-xl shadow-[#FEE2E2]' : ''}`}
        >
            <div className="flex items-center">
                <div className="w-full flex items-center text-amber-950 font-bold Product-Sans md:text-lg xl:text-xl justify-between group-[.is-active]">
                    <span className="bg-[#FEE2E2] p-3 rounded-md  text-red-600">{data.icon}</span>
                {data.question} 
                <div className="bg-[#FEE2E2] p-3 rounded-md">
                <div className="text-xl rotate-90 h-4 w-4 flex items-center justify-center cursor-pointer duration-500 text-black  group-[.is-active]:rotate-[270deg]" onClick={handleToggleActive}>
                {/* <i class="ri-arrow-right-s-fill "></i> */}
                <BsFillCaretRightFill/>
</div>

                </div>
                </div>
            </div>
            <div className="overflow-hidden Product-San text-gray-500 duration-500 group-[.is-active]:mt-8 group-[.is-active]:mb-1 text-left max-h-0 group-[.is-active]:max-h-[100px]">
            {data.answer} 
            </div>
        </div>
        

        
    );
}
export default Accordion;