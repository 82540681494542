import React, { useState, useEffect , useRef} from 'react';
import { Link, NavLink } from 'react-router-dom';
import { useLocation } from "react-router-dom";

import logowhite from '../Images/logowhite.svg'
import logo from '../Images/logo.svg'
import { HiOutlineChevronDown } from 'react-icons/hi';
import { RiPaletteLine } from 'react-icons/ri';
import { BiCodeAlt } from 'react-icons/bi';
import { BsPersonCheck, BsDatabaseCheck } from 'react-icons/bs';
import { PiWrench, PiGlobeLight } from 'react-icons/pi';
import { TfiPaintBucket } from 'react-icons/tfi';






const Navbar = () => {

  const [scrolled, setScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const hamburgerRef = useRef(null);

  const location = useLocation();
  const isHomeOrRoot = location.pathname === "/home" || location.pathname === "/";

  const handleScroll = () => {
    if (window.scrollY > 0) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

 


  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
    setIsSubMenuOpen(false);  
    if (!isMenuOpen) {
      hamburgerRef.current.checked = true;
    }
  };

  useEffect(() => {
    if (!isMenuOpen && hamburgerRef.current.checked) {
      hamburgerRef.current.checked = false;
    }
  }, [isMenuOpen]);




  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);

  const handleSubMenuToggle = () => {
    setIsSubMenuOpen(!isSubMenuOpen);
  };




  const dropdownRef = useRef(null);
  const isMouseInside = useRef(false);

  useEffect(() => {
    const closeSubMenu = () => {
      if (!isMouseInside.current) {
        setIsSubMenuOpen(false);
      }
    };

    window.addEventListener('click', closeSubMenu);

    return () => {
      window.removeEventListener('click', closeSubMenu);
    };
  }, []);

  const handleMouseEnter = () => {
    isMouseInside.current = true;
  };

  const handleMouseLeave = () => {
    isMouseInside.current = false;
  };


  
  return (
    <header className='sticky top-0 z-10'>
          <div class={`relative  z-20 shadow transition-all duration-300  bg-stone-950 ${isHomeOrRoot && !scrolled ? 'bg-transparent' : 'bg-white shadow-md'} `}>
              <div class="px-6 md:px-12 lg:container lg:mx-auto lg:px-6 lg:py-6">
                <div class="flex items-center justify-between">
                  <div class="relative z-20">
                  <Link to="/" className={`flex logo ${isHomeOrRoot && !scrolled && !isMenuOpen ? 'text-white' : 'text-gray-900'} text-2xl md:text-3xl tracking-normal`}>
                    <img src={` ${isHomeOrRoot && !scrolled && !isMenuOpen ? logowhite : logo}`} alt="RAD Media Logo" className='h-10 my-4 lg:my-0 md:h-12 lg:h-14 '/>
        {/* <h2>Deco</h2> */}
        {/* <span className="bg-clip-text text-transparent bg-gradient-to-r from-amber-700 to-amber-600 font-medium">Rama</span> */}
      </Link>
                  </div>
      
      {/* Rest of your header code */}
      <div className={`flex items-center justify-end  lg:border-l-0 ${!isHomeOrRoot || scrolled ? 'border-l' : 'border-stone-700'}`}>
        <input type="checkbox" name="hamburger" id="hamburger" className="peer"  hidden onChange={handleMenuToggle} ref={hamburgerRef}/>
        <label htmlFor="hamburger" className="peer-checked:hamburger block relative z-20 p-6 md:p-0 -mr-6 cursor-pointer lg:hidden">
          <div aria-hidden="true" className={`m-auto h-0.5 w-6 rounded ${!isHomeOrRoot || scrolled ? 'bg-sky-900 shadow-md' : 'bg-white'} transition duration-300`}></div>
          <div aria-hidden="true" className={`m-auto mt-2 h-0.5 w-6 rounded ${!isHomeOrRoot || scrolled ? 'bg-sky-900 shadow-md' : 'bg-white'} transition duration-300`}></div>
        </label>
      
                    <div class="peer-checked:translate-x-0 fixed inset-0 w-[calc(100%-4.5rem)] translate-x-[-100%] bg-white lg:bg-transparent  border-r shadow-xl transition duration-300 lg:border-r-0 lg:w-auto lg:static lg:shadow-none lg:translate-x-0 h-[100%]">
                      <div class="flex flex-col h-full justify-between lg:items-center lg:flex-row">
                        <ul class="text-left px-6 pt-32 text-gray-200 space-y-8 md:px-12 lg:space-y-0 lg:flex lg:space-x-12 lg:pt-0  text-lg  lg:text-normal Product-Sans">
                          <li>
                            <input type="checkbox" name="hamburger" id="hamburger" className="peer" hidden  />
                            <NavLink to="/home" class="group relative " onClick={handleMenuToggle}>
                              <span class={`relative ${!isHomeOrRoot || scrolled ? 'text-gray-950' : 'text-gray-950 lg:text-gray-100'}  group-hover:bg-clip-text group-hover:text-transparent group-hover:bg-gradient-to-r group-hover:from-amber-700 group-hover:to-amber-500  tracking-wide  ${location.pathname === '/home' ? 'text-red-500' : ''}` }>Home</span>
                            </NavLink>
                          </li>
                           {/* Dropdown Submenu */}
                           <li className={`relative ${!isHomeOrRoot || scrolled ? 'text-gray-900' : 'text-gray-950 lg:text-gray-100'}`} ref={dropdownRef}
     
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}  >
      <button
        onClick={handleSubMenuToggle}
        className="relative block px- py- lg:py-0 text-900 w-full"
      >
        <span className={`flex items-center justify-between w-full ${location.pathname === '/development' || location.pathname ==='/graphic-design' || location.pathname ==='/virtual-assistant' || location.pathname ==='/maintenance' || location.pathname ==='/seo' ? 'text-red-500' : ''}`}>
        <p>Services</p>
         {/* <i className={`ri-arrow-right-s-line rotate-90 lg:hidden transform transition-transform ${isSubMenuOpen ? 'rotate-90' : 'rotate-0'}`}></i> */}
         <HiOutlineChevronDown className={`  lg:ml-2 transform duration-300 transition-transform ${isSubMenuOpen ? 'rotate-180' : 'rotate-0'} ${!isHomeOrRoot || scrolled ? 'text-gray-900' : 'text-gray-950 lg:text-gray-100'} `}/></span>
      </button>
      {isSubMenuOpen && (
        <div className={` ${isHomeOrRoot && !scrolled && !isMenuOpen ? 'bg-slate-950/50 text-white lg:shadow-md' : 'bg-white text-gray-950 lg:shadow-md'} lg:absolute mt-2     sm:w-48`}>
          <ul className="sm:mt-2 md:text-[0.9rem] text-[1rem]">
            <Link to="/development" className={`px-4 py-2 flex items-center gap-2 border-b  cursor-pointer ${isHomeOrRoot && !scrolled && !isMenuOpen ?  'hover:bg-red-900 border-gray-900':  'hover:bg-red-200 border-stone-100 lg:border-stone-200'} ${location.pathname === '/development' ? 'text-red-500' : ''}`} onClick={handleMenuToggle}> <BiCodeAlt className='text-[1.5rem] md:text-[1.3rem] text-red-600'/>  Development</Link>
            <Link to="/graphic-design" className={`px-4 py-2 flex items-center gap-2   border-b cursor-pointer  ${isHomeOrRoot && !scrolled && !isMenuOpen ?  'hover:bg-red-900 border-gray-900':  'hover:bg-red-200 border-stone-100 lg:border-stone-200'} ${location.pathname === '/graphic-design' ? 'text-red-500' : ''}`} onClick={handleMenuToggle}> <TfiPaintBucket className='text-[1.5rem]  md:text-[1.3rem] text-red-600'/> Graphic Design</Link>
            <Link to="/virtual-assistant" className={`px-4 py-2 flex items-center gap-2   border-b cursor-pointer ${isHomeOrRoot && !scrolled && !isMenuOpen ?  'hover:bg-red-900 border-gray-900':  'hover:bg-red-200 border-stone-100 lg:border-stone-200'} ${location.pathname === '/virtual-assistant' ? 'text-red-500' : ''}`} onClick={handleMenuToggle}><BsPersonCheck className='text-[1.5rem] md:text-[1.3rem] text-red-600'/> Virtual Assistant</Link>
            <Link to="/maintenance" className={`px-4 py-2 flex items-center gap-2   border-b cursor-pointer ${isHomeOrRoot && !scrolled && !isMenuOpen ?  'hover:bg-red-900 border-gray-900':  'hover:bg-red-200 border-stone-100 lg:border-stone-200'} ${location.pathname === '/maintenance' ? 'text-red-500' : ''}`} onClick={handleMenuToggle}><PiWrench className='text-[1.5rem] md:text-[1.3rem] text-red-600'/> Maintenance</Link>
            {/* <Link to="/graphic-design" className={`px-4 py-2 flex items-center gap-2   border-b cursor-pointer  ${isHomeOrRoot && !scrolled && !isMenuOpen ?  'hover:bg-red-900 border-gray-900':  'hover:bg-red-200 border-stone-100 lg:border-stone-200'}`}> <RiPaletteLine className='text-[1.5rem]  md:text-[1.3rem] text-red-600'/> Graphic Design</Link> */}
            <Link to="/seo" className={`px-4 py-2 flex items-center gap-2   border-b cursor-pointer ${isHomeOrRoot && !scrolled && !isMenuOpen ?  'hover:bg-red-900 border-gray-900':  'hover:bg-red-200 border-stone-100 lg:border-stone-200'} ${location.pathname === '/seo' ? 'text-red-500' : ''}`} onClick={handleMenuToggle}><PiGlobeLight className='text-[1.5rem] md:text-[1.3rem] text-red-600'/> SEO</Link>
          </ul>
        </div>
      )}
    </li>
                          {/* <li>
                          <NavLink to="products" class="group relative   " onClick={handleMenuToggle}>
                              <span class={`relative ${!isHomeOrRoot || scrolled ? 'text-gray-900' : 'text-gray-950 lg:text-gray-100'}  group-hover:bg-clip-text group-hover:text-transparent group-hover:bg-gradient-to-r group-hover:from-amber-700 group-hover:to-amber-500  tracking-wide flex justify-between items-center ` }>Products <i class="ri-arrow-right-s-line md:hidden"></i></span>
                              </NavLink>
                          </li> */}
                              {/* <NavLinks/> */}
                          <li>
                          <NavLink to="/about" class="group relative" onClick={handleMenuToggle}>
                              <span class={`relative ${!isHomeOrRoot || scrolled ? 'text-gray-900' : 'text-gray-950 lg:text-gray-100'}  group-hover:bg-clip-text group-hover:text-transparent group-hover:bg-gradient-to-r group-hover:from-amber-700 group-hover:to-amber-500  tracking-wide ${location.pathname === '/about' ? 'text-red-500' : ''}` }>About Us</span>
                              </NavLink>
                          </li>
                          {/* <li>
                          <NavLink to="/distributers" class="group relative" onClick={handleMenuToggle}>
                              <span class={`relative ${!isHomeOrRoot || scrolled ? 'text-gray-900' : 'text-gray-950 lg:text-gray-100'}  group-hover:bg-clip-text group-hover:text-transparent group-hover:bg-gradient-to-r group-hover:from-amber-700 group-hover:to-amber-500  tracking-wide ` }>Distributers</span>
                              </NavLink>
                          </li> */}
                          <li>
                          <NavLink to="/portfolio" class="group relative" onClick={handleMenuToggle}>
                              <span class={`relative ${!isHomeOrRoot || scrolled ? 'text-gray-900' : 'text-gray-950 lg:text-gray-100'}  group-hover:bg-clip-text group-hover:text-transparent group-hover:bg-gradient-to-r group-hover:from-amber-700 group-hover:to-amber-500  tracking-wide ${location.pathname === '/portfolio' ? 'text-red-500' : ''}` }>Work</span>
                              </NavLink>
                          </li>
                          <li>
                          <NavLink to="/contact" class=" relative" onClick={handleMenuToggle}>
                              <span class={`relative ${!isHomeOrRoot || scrolled ? 'text-gray-950' : 'text-gray-950 lg:text-gray-100'}  group-hover:bg-clip-text group-hover:text-transparent group-hover:bg-gradient-to-r group-hover:from-amber-700 group-hover:to-amber-500  tracking-wide  ${location.pathname === '/contact' ? 'text-red-500' : ''}` }>Contact </span>
                              </NavLink>
                          </li>
                        </ul>
      
                        <div class="border-t py-8 px-6 md:px-12 md:py-16 lg:border-t-0 lg:border-l lg:py-0 lg:pr-0 lg:pl-6">
                        <Link to="/contact" class="block px-6 py-3 rounded-full bg-gradient-to-r from-red-800 to-red-500 text-center text-white Product-Sans" onClick={handleMenuToggle}>
                          Enquire Now
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </header>
  )
}

export default Navbar