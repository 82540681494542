import React from 'react'
import logowhite from '../Images/logowhite.svg'
import { Link } from 'react-router-dom'
import Development from './development';
import Maintenance from './Maintenance';



const Footer = () => {
  return (
//     <div className='bg-stone-900 '>
//         <div className="flex flex-col md:flex-row justify-between container py-10 px-6  mx-auto  ">
//             <div className=" md:text-left w-full md:w-[40%] lg:w-auto">
//             <Link to="/" class={` flex logo text-center md:text-left  text-white justify-center md:justify-start  text-2xl md:text-3xl  tracking-norma `}>
//                       <h2 >Deco</h2>
//                       <span class="bg-clip-text text-transparent bg-gradient-to-r from-amber-700 to-amber-600 font-medium">Rama</span>
//                     </Link>
//                 <div className="text-base text-center md:text-left font-normal text-gray-400">Our vision is to make all people
// the best place to live for them.</div>
//             </div>
//             <div className="">
//             <div className="text-lg md:text-2xl mt-6 md:mt-0 font-bold Product-Sans text-center md:text-left text-white">
//                 Visit Us At
//                 <div className='text-base font-normal text-gray-400'> PBR Towers, Sevoke Road, Siliguri, West Bengal</div>

//                 <div className="flex  justify-between gap-3 text-base lg:text-lg text-left font-medium mt-8 md:mt-4 Product-Sans text-white">
//                 <Link to="/products">Products</Link><Link to="/distributers">Distributers</Link><Link to="/contact">Contact Us</Link>
//                 </div>
//             </div>
//             </div>
//         </div>
//         {/* <div className="bg-stone-950">

//         <div className="container flex-col md:flex-row text-center text-gray-400 py-3 mx-auto text-[0.9rem] md:text-left Product-Sans font-medium flex justify-between">
//             <span>Copyright <span className='mont'>©</span> 2023 DecoRama. All rights reserved.</span><span className='mt-2 md:mt-0'>Powered by <a  href="https://primsyindia.com/" target='_blank' className='font-semibold cursor-pointer'>Primsy India Pvt. Ltd.</a></span>
//         </div>
//         </div> */}
//     </div>


<footer class="text-gray-400 bg-gray-900 body-font">
  <div class="container px-5 py-24 mx-auto flex md:items-center lg:items-start md:flex-row md:flex-nowrap flex-wrap flex-col">
    <div class="w-64 flex-shrink-0 md:mx-0 mx-auto text-center md:text-left">
      <a class="flex title-font font-medium items-center md:justify-start justify-center text-white">
        <img src={logowhite} alt="" className='w-32' />
      </a>
      <p class="mt-2 text-sm text-gray-500">Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit, odit?</p>
    </div>
    <div class="flex-grow flex flex-wrap md:pl-20 -mb-10 md:mt-0 mt-10 md:text-left text-center">
      <div class="lg:w-1/4 md:w-1/2 w-full px-4">
        <h2 class="title-font font-medium text-white tracking-widest text-sm mb-3">NAVIGATION</h2>
        <nav class="list-none mb-10 flex flex-col gap-1">
          <li>
            <Link to="/" class="text-gray-400 hover:text-white cursor-pointer">Home</Link>
          </li>
          <li>
            <Link to="/about" class="text-gray-400 hover:text-white cursor-pointer">About Us</Link>
          </li>
          <li>
            <Link to="/portfolio" class="text-gray-400 hover:text-white cursor-pointer">Work</Link>
          </li>
          <li>
            <Link to="/contact" class="text-gray-400 hover:text-white cursor-pointer">Contact</Link>
          </li><li>
            <Link to="/" class="text-gray-400 hover:text-white cursor-pointer">Terms & Conditions</Link>
          </li>
        </nav>
      </div>
      <div class="lg:w-1/4 md:w-1/2 w-full px-4">
        <h2 class="title-font font-medium text-white tracking-widest text-sm mb-3">SERVICES</h2>
        <nav class="list-none mb-10 flex flex-col gap-1">
          <li>
            <Link to="/development" class="text-gray-400 hover:text-white cursor-pointer">Development</Link>
          </li>
          <li>
            <Link to="/graphic-design" class="text-gray-400 hover:text-white cursor-pointer">Graphic Design</Link>
          </li>
          <li>
            <Link to="/virtual-assistant" class="text-gray-400 hover:text-white cursor-pointer">Virtual Assistant</Link>
          </li>
          <li>
            <Link to="/maintenance" class="text-gray-400 hover:text-white cursor-pointer">Maintenance</Link>
          </li>
          <li>
            <Link to="/seo" class="text-gray-400 hover:text-white cursor-pointer">SEO</Link>
          </li>
        </nav>
      </div>
      <div class="lg:w-1/2 md:w-1/2 w-full px-4">
      <h2 class="title-font font-medium text-white tracking-widest text-sm mb-3">NEWSLETTERS</h2>

        <div class="text-gray-400 hover:text-white mb-4">Subscribe to our newletters to get updates on latest news and offers</div>
      <div class="flex md:flex-nowrap flex-wrap justify-center items-end md:justify-start">
        <div class="relative sm:w-64 w-40 sm:mr-4 mr-2">
          <input type="text" id="footer-field" name="footer-field" class="w-full bg-gray-50 bg-opacity- rounded border border-gray-300 focus:ring-2 focus:bg-transparent focus:ring-indigo-200 focus:border-indigo-500 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" placeholder='email@mail.com'/>
        </div>

        <button class="inline-flex text-white bg-gradient-to-r from-red-800 to-red-500 border-0 py-2 px-6 focus:outline-none hover:from-red-800 hover:to-red-500 rounded">Subscribe</button>
       
      </div>
      </div>
      
    </div>
  </div>
  <div class="bg-gray-800 bg-opacity-75">
    <div class="container mx-auto py-4 px-5 flex flex-wrap flex-col sm:flex-row">
      <p class="text-gray-400 text-sm text-center justify-center md:text-left flex items-center">© 2023 RAD Media —
        <a href="https://twitter.com/knyttneve" rel="noopener noreferrer" class="text-gray-500 ml-1 flex items-center " target="_blank">Crafted with <span className='text-red-600 px-1 text-lg'> ♥️ </span> by RAD Media.</a>
      </p>
      <span class="inline-flex sm:ml-auto sm:mt-0 mt-2 justify-center sm:justify-start">
        <a class="text-gray-400 cursor-pointer">
          <svg fill="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
            <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
          </svg>
        </a>
        <a class="ml-3 text-gray-400 cursor-pointer">
          <svg fill="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
            <path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"></path>
          </svg>
        </a>
        <a class="ml-3 text-gray-400 cursor-pointer">
          <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
            <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
            <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"></path>
          </svg>
        </a>
        <a class="ml-3 text-gray-400 cursor-pointer">
          <svg fill="currentColor" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="0" class="w-5 h-5" viewBox="0 0 24 24">
            <path stroke="none" d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z"></path>
            <circle cx="4" cy="4" r="2" stroke="none"></circle>
          </svg>
        </a>
      </span>
    </div>
  </div>
</footer>
  )
}

export default Footer