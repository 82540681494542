import React, { useState } from 'react'
import { Link } from 'react-router-dom'


const Plywood = () => {


  //Marco
  const [imagesMarco, setImagesMarco] = useState({
    img1 : require("../Images/ply.jpg"),
    img2 : require("../Images/ply2.jpg"),
    img3 : require("../Images/ply3.jpg"),
    img4 : require("../Images/ply.jpg"),
})

const [activeMarcoImg, setActiveMarcoImage] = useState(imagesMarco.img1)

//Vaastu
const [imagesVaastu, setImagesVaastu] = useState({
  img1 : require("../Images/ply.jpg"),
  img2 : require("../Images/ply2.jpg"),
  img3 : require("../Images/ply3.jpg"),
  img4 : require("../Images/ply.jpg"),
})

const [activeVaastuImg, setActiveVaastuImage] = useState(imagesVaastu.img1)

//Saburi
const [imagesSaburi, setImagesSaburi] = useState({
  img1 : require("../Images/ply.jpg"),
  img2 : require("../Images/ply2.jpg"),
  img3 : require("../Images/ply3.jpg"),
  img4 : require("../Images/ply.jpg"),
})

const [activeSaburiImg, setActiveSaburiImage] = useState(imagesSaburi.img1)



  return (
    <div className=" grid items-center  ">
      <div className="bg-gray-800">
      <div className=" container mx-auto">
<nav aria-label="breadcrumb" className="w- p-4  bg-gray-800 text-gray-100">
	<ol className="flex h-8 space-x-2">
		<li className="flex items-center">
    <Link  to="/home" title="Back to homepage" className="hover:no-underline">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5 pr-1 dark:text-gray-400">
					<path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path>
				</svg>
			</Link>
		</li>
		<li className="flex items-center space-x-2">
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" aria-hidden="true" fill="currentColor" className="w-2 h-2 mt-1 transform rotate-90 fill-current dark:text-gray-600">
				<path d="M32 30.031h-32l16-28.061z"></path>
			</svg>
			<Link  to="/products" className="flex items-center px-1 capitalize hover:no-underline">Products</Link>
		</li>
		<li className="flex items-center space-x-2">
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" aria-hidden="true" fill="currentColor" className="w-2 h-2 mt-1 transform rotate-90 fill-current dark:text-gray-600">
				<path d="M32 30.031h-32l16-28.061z"></path>
			</svg>
			<Link  to="/plywood" className="flex items-center px-1 capitalize hover:no-underline">Plywood</Link>
		</li>
		
	</ol>
</nav>
</div>
</div>
      {/* <div className="Product-Sans text-4xl mt-10 px-4 md:px-4 font-bold mx-auto container bg-clip-text block  text-transparent bg-gradient-to-r from-amber-600 to-amber-500">
        <span>Plywoods</span> </div> */}
     

{/* Marco */}
        <div className="grid grid-cols-2  px-8 md:px-0  py-10 justify-center container mx-auto gap-10">
          <div className="col-span-full md:col-span-1 anim-product">
          <div className='flex flex-col gap-2 md:gap-6 '>
                <img src={activeMarcoImg} alt="" className='w-full h-[20rem] md:h-[32rem] aspect-square object-cover rounded-xl'/>
                <div className='flex flex-row justify-between md:justify-evenly h-24'>
                    <img src={imagesMarco.img1} alt="" className='w-20 md:w-24 h-20 md:h-24 rounded-md cursor-pointer' onClick={() => setActiveMarcoImage(imagesMarco.img1)}/>
                    <img src={imagesMarco.img2} alt="" className='w-20 md:w-24 h-20 md:h-24 rounded-md cursor-pointer' onClick={() => setActiveMarcoImage(imagesMarco.img2)}/>
                    <img src={imagesMarco.img3} alt="" className='w-20 md:w-24 h-20 md:h-24 rounded-md cursor-pointer' onClick={() => setActiveMarcoImage(imagesMarco.img3)}/>
                    <img src={imagesMarco.img4} alt="" className='w-20 md:w-24 h-20 md:h-24 rounded-md cursor-pointer' onClick={() => setActiveMarcoImage(imagesMarco.img4)}/>
                </div>
            </div>
          </div>
          <div className="col-span-full md:col-span-1 flex items-center animate-slide-fade-product">
          <div className='flex flex-col Product-Sans gap-4 '>
                <div>
                    <span className='  bg-clip-text block font-semibold text-transparent bg-gradient-to-r from-amber-600 to-amber-500'>Premium Product</span>
                    <h1 className='text-3xl font-bold'>Marcos Plywood</h1>
                </div>
                <p className='text-gray-600 md:text-base xl:text-lg font-medium'>
                With a commitment to excellence and unmatched craftsmanship, Marcos Plywood stands tall as a leading brand in Siliguri, Sikkim & the entirety of West Bengal.
We understand the significance of reliable plywood for all your construction and interior needs. With years of expertise and an unwavering dedication to delivering premium products, we have built a solid reputation among homeowners, contractors, and architects alike.
<br/><br/>Our range of plywood is meticulously crafted using the finest materials and advanced manufacturing techniques, ensuring durability, strength, and impeccable finish. Whether you're working on residential projects, commercial establishments, or industrial spaces, Marcos Plywood offers a wide array of options to cater to your unique requirements.

                </p>

                <div className="flex-grow text-[#315FCA] py-2 text-base px-1 cursor-pointer">
                    <div className="flex border-t border-gray-200 py-2">
                      <span className="text-gray-500">Thickness:</span>
                      <span className="ml-auto text-gray-900">12mm</span>
                    </div>
                    <div className="flex border-t border-gray-200 py-2">
                      <span className="text-gray-500">Sizes:</span>
                      <span className="ml-auto text-gray-900">
                      4 x 8 feet
                      </span>
                    </div>
                    <div className="flex border-t border-gray-200 py-2">
                      <span className="text-gray-500">Specifications:</span>
                      <span className="ml-auto text-gray-900">
                      Moisture Resistant
                      </span>
                    </div>
                    <div className="flex border-t border-b border-gray-200 py-2">
                      <span className="text-gray-500">Features:</span>
                      <span className="ml-auto text-gray-900">
                      High Impact Resistance
                      </span>
                    </div>
                  </div>

                <div className='flex flex-row items-center mt-4 gap-12'>
                  
                <Link to="/distributers" className=' text-white font-semibold py-3 cursor-pointer w-full md:w-1/3 bg-gradient-to-r from-amber-800 to-amber-500 text-center  rounded-xl h-full'>Our Vendors</Link>
                </div>
            </div>
          </div>
        </div>

{/* Vaastu */}
        <div className="grid grid-cols-2 px-8 md:px-0  py-10 justify-center container mx-auto gap-10 ">
        <div className="col-span-full order-last md:order-first md:col-span-1 flex items-center animate-slide-fade-product">
          <div className='flex flex-col Product-Sans gap-4 '>
                <div>
                    <span className=' text-violet-60 bg-clip-text block font-semibold text-transparent bg-gradient-to-r from-amber-600 to-amber-500'>Premium Product</span>
                    <h1 className='text-3xl font-bold'>Vaastu  Plywood</h1>
                </div>
                <p className='text-gray-600 md:text-base xl:text-lg  font-medium'>
                Introducing Vaastu Plywood – your perfect choice for residential and hotel interiors in Siliguri, West Bengal, and Sikkim. Crafted with precision and passion, Vaastu Plywood brings a touch of elegance and durability to your spaces. Elevate your living environment with our premium plywood, carefully engineered to withstand the test of time. Experience the seamless blend of functionality and aesthetic appeal, ensuring your spaces exude warmth and sophistication. Choose Vaastu Plywood for a transformative experience that surpasses expectations. Create a harmonious living ambiance with the finest pocket-friendly plywood brand in town!

                </p>

             
                <div className="flex-grow text-[#315FCA] py-2 text-base px-1 cursor-pointer">
                    <div className="flex border-t border-gray-200 py-2">
                      <span className="text-gray-500">Thickness:</span>
                      <span className="ml-auto text-gray-900">12mm</span>
                    </div>
                    <div className="flex border-t border-gray-200 py-2">
                      <span className="text-gray-500">Sizes:</span>
                      <span className="ml-auto text-gray-900">
                      4 x 8 feet
                      </span>
                    </div>
                    <div className="flex border-t border-gray-200 py-2">
                      <span className="text-gray-500">Specifications:</span>
                      <span className="ml-auto text-gray-900">
                      Moisture Resistant
                      </span>
                    </div>
                    <div className="flex border-t border-b border-gray-200 py-2">
                      <span className="text-gray-500">Features:</span>
                      <span className="ml-auto text-gray-900">
                      High Impact Resistance
                      </span>
                    </div>
                  </div>

                <div className='flex flex-row items-center mt-4 gap-12'>
                <Link to="/distributers" className=' text-white font-semibold py-3 cursor-pointer w-full md:w-1/3 bg-gradient-to-r from-amber-800 to-amber-500 text-center  rounded-xl h-full'>Our Vendors</Link>
                </div>
            </div>
          </div>

          <div className="col-span-full order-first md:order-last md:col-span-1 anim-product ">
          <div className='flex flex-col gap-2 md:gap-6 '>
                <img src={activeVaastuImg} alt="" className='w-full h-[20rem] md:h-[32rem] aspect-square object-cover rounded-xl'/>
                <div className='flex flex-row justify-between md:justify-evenly h-24'>
                    <img src={imagesVaastu.img1} alt="" className='w-20 md:w-24 h-20 md:h-24 rounded-md cursor-pointer' onClick={() => setActiveVaastuImage(imagesVaastu.img1)}/>
                    <img src={imagesVaastu.img2} alt="" className='w-20 md:w-24 h-20 md:h-24 rounded-md cursor-pointer' onClick={() => setActiveVaastuImage(imagesVaastu.img2)}/>
                    <img src={imagesVaastu.img3} alt="" className='w-20 md:w-24 h-20 md:h-24 rounded-md cursor-pointer' onClick={() => setActiveVaastuImage(imagesVaastu.img3)}/>
                    <img src={imagesVaastu.img4} alt="" className='w-20 md:w-24 h-20 md:h-24 rounded-md cursor-pointer' onClick={() => setActiveVaastuImage(imagesVaastu.img4)}/>
                </div>
            </div>
          </div>
         
        </div>

{/* Saburi */}
<div className="grid grid-cols-2  px-8 md:px-0  py-10 justify-center container mx-auto gap-10">
          <div className="col-span-full md:col-span-1 anim-product">
          <div className='flex flex-col gap-2 md:gap-6 '>
                <img src={activeSaburiImg} alt="" className='w-full h-[20rem] md:h-[32rem] aspect-square object-cover rounded-xl'/>
                <div className='flex flex-row justify-between md:justify-evenly h-24'>
                    <img src={imagesSaburi.img1} alt="" className='w-20 md:w-24 h-20 md:h-24 rounded-md cursor-pointer' onClick={() => setActiveSaburiImage(imagesSaburi.img1)}/>
                    <img src={imagesSaburi.img2} alt="" className='w-20 md:w-24 h-20 md:h-24 rounded-md cursor-pointer' onClick={() => setActiveSaburiImage(imagesSaburi.img2)}/>
                    <img src={imagesSaburi.img3} alt="" className='w-20 md:w-24 h-20 md:h-24 rounded-md cursor-pointer' onClick={() => setActiveSaburiImage(imagesSaburi.img3)}/>
                    <img src={imagesSaburi.img4} alt="" className='w-20 md:w-24 h-20 md:h-24 rounded-md cursor-pointer' onClick={() => setActiveSaburiImage(imagesSaburi.img4)}/>
                </div>
            </div>
          </div>
          <div className="col-span-full md:col-span-1 flex items-center animate-slide-fade-product">
          <div className='flex flex-col Product-Sans gap-4 '>
                <div>
                    <span className='  bg-clip-text block font-semibold text-transparent bg-gradient-to-r from-amber-600 to-amber-500'>Premium Product</span>
                    <h1 className='text-3xl font-bold'>Saburi Plywood</h1>
                </div>
                <p className='text-gray-600 md:text-base xl:text-lg  font-medium'>
                Saburi Ply is a dynamic, professionally managed manufacturing Company. A leader in the panel products industry in India for two decades. The Company over the years has grown as a Plywood manufacturer in entire India. The Chairman, Mr. Gajanand Munka started this business as early as 1990. It has been running for two decades with an aim to globalise this industry for quality enhancement.
Saburi Ply as a brand does not merely bank on the existing profile of successful products, but we also continuously introduce futuristic products for the benefit of our customers. We at Decorama are distributors of this super high-quality brand.


                </p>

           
                <div className="flex-grow text-[#315FCA] py-2 text-base px-1 cursor-pointer">
                    <div className="flex border-t border-gray-200 py-2">
                      <span className="text-gray-500">Thickness:</span>
                      <span className="ml-auto text-gray-900">12mm</span>
                    </div>
                    <div className="flex border-t border-gray-200 py-2">
                      <span className="text-gray-500">Sizes:</span>
                      <span className="ml-auto text-gray-900">
                      4 x 8 feet
                      </span>
                    </div>
                    <div className="flex border-t border-gray-200 py-2">
                      <span className="text-gray-500">Specifications:</span>
                      <span className="ml-auto text-gray-900">
                      Moisture Resistant
                      </span>
                    </div>
                    <div className="flex border-t border-b border-gray-200 py-2">
                      <span className="text-gray-500">Features:</span>
                      <span className="ml-auto text-gray-900">
                      High Impact Resistance
                      </span>
                    </div>
                  </div>

                <div className='flex flex-row items-center mt-4 gap-12'>
                <Link to="/distributers" className=' text-white font-semibold py-3 cursor-pointer w-full md:w-1/3 bg-gradient-to-r from-amber-800 to-amber-500 text-center  rounded-xl h-full'>Our Vendors</Link>
                </div>
            </div>
          </div>
        </div>

        </div>
  )
}

export default Plywood