import React, { Fragment } from "react";
import Navbar from "./Navbar";
import Routers from "../Routers/Routers";
import Footer from "./Footer";

const Layout = () => {
  return (
    <Fragment>
        <Navbar />
        <Routers/>
        <Footer/>
    </Fragment>
  )
}

export default Layout