import { useState, useEffect } from 'react'
import React from 'react'
import { Link } from 'react-router-dom'
import CountUp from './CountUp'


const HeroSection = () => {


  return (
    <div>
        <section  class="h-[90vh] w-full absolute top-0 left-0 " >
       

       <div class=" grid grid-cols-2 grid-col-reverse  h-[100vh] gap-4 lg:container lg:mx-auto lg:px-6 ">
           <div class="bg-lime-00  md:order-first col-span-full md:col-span-1 flex items-center  xl:pr-10 anim-slide-img">

                <div className="bg-gray-30 mx-auto w-[65%] md:w-[90%] xl:w-3/4 h-[45vh] md:h-[78vh] mt-10 md:mt-24 rounded-t-full  border-8 bg-cover bg-center overflow-hidden inner-s shadow-rose-700" id="hero-img" >
                </div>
           </div>
           
           <div class=" order-first  col-span-full md:col-span-1 grid items-center animate-slide-fade-in">
               <div class="flex items-center flex-wrap gap-12 lg:gap-0">
                   <div class="lg:w-10/1 w-full mt-20 md:mt-0 md:pl-4 lg:pl-10">
                      
                           {/* <span class="block w-14 mb-2 border-b-2 border-gray-600"></span> */}
                           <span class="bg-clip-text block text-transparent bg-gradient-to-r from-red-600 to-red-500 text-xl font-semibold Product-Sans mt-12 md:py-2 text-center md:text-left mb-4">Fusing Innovation and Artistry</span>
                 
                       <h1 class="text-3xl mb-2 md:mb-0 px-4 md:px-0 font-medium md:text-4xl xl:text-5xl md:font-normal 2xl:text-6xl leading-snug md:leading-snug xl:leading-snug text-white mont text-center md:text-left ">Shaping Digital Success <br/> Through Innovation and Creativity</h1>
                       <div className="md:w-[90%] xl:w-[60%] md:mt-4 2xl:mt-8">
                       <div className="flex px-6 md:px-0 md:py-0 justify-center md:justify-start items-center gap-8">
                   <div class=" py-4  w-44">
                          <a href="#services" class="block  px-4 xl:px-6 py-2 xl:py-3 rounded-full bg-gradient-to-r from-red-800 to-red-500 text-center text-white ">
                            Get Started
                          </a>
                        </div>

                        <div class=" py-4 ">
                          <a href="#" class="flex gap-1  py-3 items-center text-gray-400">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-7 h-7">
  <path strokeLinecap="round" strokeLinejoin="round" d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
  <path strokeLinecap="round" strokeLinejoin="round" d="M15.91 11.672a.375.375 0 010 .656l-5.603 3.113a.375.375 0 01-.557-.328V8.887c0-.286.307-.466.557-.327l5.603 3.112z" />
</svg>

                            View Portfolio
                          </a>
                        </div>
                        </div>

                        <div className="grid grid-cols-3 mt-8 px-14 md:px-0 gap-2 2xl:mt-6">
                            <div className="col-span-1 text-white poppins text-2xl md:text-3xl xl:text-4xl font-medium text-center md:text-left">
                            <CountUp target={14} duration={100} />K<span className='bg-clip-text text-transparent bg-gradient-to-r from-red-600 to-red-500 font-medium poppins'>+</span>
                            <div className="text-gray-400 poppins text-sm md:text-base font-normal mt-2 leading-5">Premium <br/> Services</div>
                            </div>
                            <div className="col-span-1 text-white poppins text-2xl md:text-3xl xl:text-4xl font-medium text-center md:text-left">
                            <CountUp target={4} duration={100} />K<span className='bg-clip-text text-transparent bg-gradient-to-r from-red-600 to-red-500 font-medium poppins'>+</span>
                            <div className="text-gray-400 poppins text-sm md:text-base font-normal mt-2 leading-5">Happy <br/> Customer</div>
                            </div>
                            <div className="col-span-1 text-white poppins text-2xl md:text-3xl xl:text-4xl font-medium text-center md:text-lefts">
                            <CountUp target={24} duration={100} /><span className='bg-clip-text text-transparent bg-gradient-to-r from-red-600 to-red-500 font-medium poppins'>+</span>
                            <div className="text-gray-400 poppins text-sm md:text-base font-normal mt-2 leading-5">Awards <br/> Winning</div>
                            </div>
                        </div>

                        </div>
                   </div>
               </div>
               
           </div>
         </div>
       <div class="h-[100vh] w-full absolute top-0 left-0 bg-stone-950 mesh backdrop-blur -z-10 " id="bg-clip"></div>
   </section>

   {/* <section class="h-[100vh] mt-[100vh]" ></section> */}
    </div>
  )
}

export default HeroSection