import React from 'react'
import { NavLink } from 'react-router-dom';
import ServicesCard from './ServicesCard';

const Services = () => {
  return (
    <div className=" mt-[110vh] md:mt-[100vh] container py-8 overflow-hidden h-[100v] mx-auto"  id='services'>

    <div className="text-4xl text-center md:text-left flex flex-col Product-Sans my-10 font-semibold">
      <span className="text-xl text-center md:text-left  Product-Sans  font-semibold bg-clip-text block  text-transparent bg-gradient-to-r from-red-600 to-red-500">Discover </span>
    Our Services
    </div>

    <ServicesCard/>
    </div>
  )
}

export default Services