import React, { Fragment } from 'react'
import { Routes, Route, Navigate,Switch  } from "react-router-dom";
import Home from '../Components/Home';
import Products from '../Components/Products';
import Plywood from '../Components/Plywood';
import Laminates from '../Components/Laminates';
import Acrylic from '../Components/Acrylic';
import Louvers from '../Components/Louvers';
import Veneers from '../Components/Veneers';
import WoodenFlooring from '../Components/WoodenFlooring';
import Highlighters from '../Components/Highlighters';
import Hardware from '../Components/Hardware';
import Decoratives from '../Components/Decoratives';
import ScrollToTop from '../utils/ScrollToTop';
import Distributers from '../Components/Distributers';
import Contact from '../Components/Contact';
import Error from '../Components/Error';
import Development from '../Components/development';
import Graphics from '../Components/Graphics';
import VirtualAssistance from '../Components/VirtualAssistance';
import Seo from '../Components/Seo';
import Maintenance from '../Components/Maintenance';
import About from '../Components/About';
import Work from '../Components/Work';



const Routers = () => {
  return (
    <Fragment>
    <ScrollToTop />
    <Routes>
        <Route path="*" element={<Error />} />
        <Route path="/" element={<Navigate to="/home" />} />
        <Route path="/home" element={<Home />} />
        <Route path="/products" element={<Products />} />
        <Route path="/plywood" element={<Plywood />} />
        <Route path="/laminates" element={<Laminates />} />
        <Route path="/acrylic" element={<Acrylic />} />
        <Route path="/louvers" element={<Louvers />} />
        <Route path="/veneers" element={<Veneers />} />
        <Route path="/wooden-flooring" element={<WoodenFlooring />} />
        <Route path="/highlighters" element={<Highlighters />} />
        <Route path="/hardware" element={<Hardware />} />
        <Route path="/decoratives" element={<Decoratives />} />
        <Route path="/distributers" element={<Distributers />} />
        <Route path="/contact" element={<Contact />} />


        <Route path="/development" element={<Development />} />
        <Route path="/graphic-design" element={<Graphics />} />
        <Route path="/virtual-assistant" element={<VirtualAssistance />} />
        <Route path="/seo" element={<Seo />} />
        <Route path="/maintenance" element={<Maintenance />} />
        <Route path="/about" element={<About />} />
        <Route path="/portfolio" element={<Work />} />


        
        
        
        
        
        

    </Routes>
    </Fragment>
  )
}

export default Routers