import React from 'react'

const Cta = () => {
  return (

        <div className="px-4 md:px-0">
    <div className="w-full my-10  container mx-auto rounded-xl bg-black backdrop-blur bg-cover" id='cta'>
	<div className="container flex flex-col flex-wrap content-center justify-center Product-Sans p-4 py-20 mx-auto md:p-10">
		<h1 className="text-3xl md:text-5xl antialiased font-semibold leadi text-center text-gray-100">Get Our Updates</h1>
		<p className="pt-2 pb-8 text-xl antialiased text-center dark:text-gray-100">Find out about offer and other news</p>
		<div className="flex flex-row">
			<input type="text" placeholder="example@email.com" className="w-3/5 p-3 rounded-l-lg sm:w-2/3" />
			<button type="button" className="w-2/5 p-3 font-semibold rounded-r-lg sm:w-1/3 bg-gradient-to-r from-slate-700 to-slate-600 text-gray-100">Subscribe</button>
		</div>
	</div>
</div>


</div>
                                    

  )
}

export default Cta