import React from 'react'
import Accordion from './Accordion'
import { useState } from 'react';

const Faq = () => {

    const [list, setList] = useState([
        {
          icon: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5">
          <path fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zM9 7.5A.75.75 0 009 9h1.5c.98 0 1.813.626 2.122 1.5H9A.75.75 0 009 12h3.622a2.251 2.251 0 01-2.122 1.5H9a.75.75 0 00-.53 1.28l3 3a.75.75 0 101.06-1.06L10.8 14.988A3.752 3.752 0 0014.175 12H15a.75.75 0 000-1.5h-.825A3.733 3.733 0 0013.5 9H15a.75.75 0 000-1.5H9z" clipRule="evenodd" />
        </svg>,
        
          question: "Best price on the market",
          answer: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus vel eos illo tenetur voluptates officia, possimus quam, a quaerat quidem debitis quia corrupti delectus.",
          active: 1
        },
        {
            icon: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5">
            <path fillRule="evenodd" d="M12.516 2.17a.75.75 0 00-1.032 0 11.209 11.209 0 01-7.877 3.08.75.75 0 00-.722.515A12.74 12.74 0 002.25 9.75c0 5.942 4.064 10.933 9.563 12.348a.749.749 0 00.374 0c5.499-1.415 9.563-6.406 9.563-12.348 0-1.39-.223-2.73-.635-3.985a.75.75 0 00-.722-.516l-.143.001c-2.996 0-5.717-1.17-7.734-3.08zm3.094 8.016a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z" clipRule="evenodd" />
          </svg>,
          question: "Quality with variety services",
          answer: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus vel eos illo tenetur voluptates officia, possimus quam, a quaerat quidem debitis quia corrupti delectus.",
        },
        {
            icon: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5">
            <path fillRule="evenodd" d="M12.516 2.17a.75.75 0 00-1.032 0 11.209 11.209 0 01-7.877 3.08.75.75 0 00-.722.515A12.74 12.74 0 002.25 9.75c0 5.942 4.064 10.933 9.563 12.348a.749.749 0 00.374 0c5.499-1.415 9.563-6.406 9.563-12.348 0-1.39-.223-2.73-.635-3.985a.75.75 0 00-.722-.516l-.143.001c-2.996 0-5.717-1.17-7.734-3.08zm3.094 8.016a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z" clipRule="evenodd" />
          </svg>,
          question: "Unmatched customer service",
          answer: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus vel eos illo tenetur voluptates officia, possimus quam, a quaerat quidem debitis quia corrupti delectus.",
        }
      ]);
  return (
    <div className="grid mx-auto  container grid-cols-2 gap-8 mt-10">
            

            <div className="col-span-full mx-auto w-[90%] md:col-span-1 md:order-first grid self-center md:pl-8">
            <div className="text-4xl text-left flex flex-col Product-Sans my-10 font-semibold">
        <span className="text-xl text-left  Product-Sans  font-semibold bg-clip-text block  text-transparent bg-gradient-to-r from-red-600 to-red-500">
Our Value </span>
        Value We Give to You
      <div className="text-sm text-left text-gray-500 font-medium mt-3 mont">We always ready to help by providing the best services for you.
We beleive a good blace to live can make your life better</div>
      </div>
            {
        list.map((item, key) => (
          <Accordion key={key} datas={item} />
        ))
      }
            </div>
            
            <div className="col-span-full md:col-span-1 md:order-first grid self-center">
            <div class="bg-lime-00  md:order-first col-span-full md:col-span-1 flex items-center  xl:pl-10 ">

<div className="bg-gray-30 mx-auto w-[65%] md:w-[90%] xl:w-3/4 h-[45vh] md:h-[78vh]  rounded-t-full  border-8 bg-cover bg-center overflow-hidden shadow-inner shadow-gray-400" id="faq-img" >
</div>
</div>
            </div>
            </div>
  )
}

export default Faq