import React, { useState } from 'react'


import Quick from "../Images/Quick.jpg"
import Tesa from "../Images/Tesa.jpg"
import Casa from "../Images/Casa.jpg"
import Excel from "../Images/Excel.jpg"
import { Link } from 'react-router-dom'

const WoodenFlooring = () => {

 //Louvers
 const [imagesMica, setImagesMica] = useState({
  img1 : require("../Images/ply.jpg"),
  img2 : require("../Images/ply2.jpg"),
  img3 : require("../Images/ply3.jpg"),
  img4 : require("../Images/ply.jpg"),
})

const [activeMicaImg, setActiveMicaImage] = useState(imagesMica.img1)



  return (
    <div className=" grid items-center ">
    <div className="bg-gray-800">
    <div className=" container mx-auto">
<nav aria-label="breadcrumb" className="w- p-4  bg-gray-800 text-gray-100">
  <ol className="flex h-8 space-x-2">
      <li className="flex items-center">
      <Link  to="/home" title="Back to homepage" className="hover:no-underline">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5 pr-1 dark:text-gray-400">
                  <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path>
              </svg>
          </Link>
      </li>
      <li className="flex items-center space-x-2">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" aria-hidden="true" fill="currentColor" className="w-2 h-2 mt-1 transform rotate-90 fill-current dark:text-gray-600">
              <path d="M32 30.031h-32l16-28.061z"></path>
          </svg>
          <Link  to="/products" className="flex items-center px-1 capitalize hover:no-underline">Products</Link>
      </li>
      <li className="flex items-center space-x-2">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" aria-hidden="true" fill="currentColor" className="w-2 h-2 mt-1 transform rotate-90 fill-current dark:text-gray-600">
              <path d="M32 30.031h-32l16-28.061z"></path>
          </svg>
          <Link  to="/wooden-flooring" className="flex items-center px-1 capitalize hover:no-underline">Wooden Flooring</Link>
      </li>
      
  </ol>
</nav>
</div>
</div>



<div className="block container px-4 py-12 mx-auto">
    {/* <div className="Product-Sans bg-clip-text block  text-transparent bg-gradient-to-r from-amber-600 to-amber-500 text-2xl md:text-4xl px-4 md:px-0 font-semibold">Unleash elegance with our exquisite veneers.
</div> */}



{/* Mica */}
<div className="grid grid-cols-2  px-4 md:px-0  py-10 justify-center container mx-auto gap-10">
          <div className="col-span-full md:col-span-1 anim-product">
          <div className='flex flex-col gap-2 md:gap-6 '>
                <img src={activeMicaImg} alt="" className='w-full h-[20rem] md:h-[32rem] aspect-square object-cover rounded-xl'/>
                <div className='flex flex-row justify-between md:justify-evenly h-24'>
                    <img src={imagesMica.img1} alt="" className='w-20 md:w-24 h-20 md:h-24 rounded-md cursor-pointer' onClick={() => setActiveMicaImage(imagesMica.img1)}/>
                    <img src={imagesMica.img2} alt="" className='w-20 md:w-24 h-20 md:h-24 rounded-md cursor-pointer' onClick={() => setActiveMicaImage(imagesMica.img2)}/>
                    <img src={imagesMica.img3} alt="" className='w-20 md:w-24 h-20 md:h-24 rounded-md cursor-pointer' onClick={() => setActiveMicaImage(imagesMica.img3)}/>
                    <img src={imagesMica.img4} alt="" className='w-20 md:w-24 h-20 md:h-24 rounded-md cursor-pointer' onClick={() => setActiveMicaImage(imagesMica.img4)}/>
                </div>
            </div>
          </div>
          <div className="col-span-full md:col-span-1 flex items-center animate-slide-fade-product">
          <div className='flex flex-col Product-Sans gap-4 '>
                <div>
                    <span className='  bg-clip-text block font-semibold text-transparent bg-gradient-to-r from-amber-600 to-amber-500'>Premium Product</span>
                    <h1 className='text-3xl font-bold'>Wooden Flooring</h1>
                </div>
                <p className='text-gray-600 md:text-base xl:text-lg  font-medium'>
                Transform your space with our exquisite wooden floorings. Crafted from premium hardwoods, our floors offer timeless beauty and durability. Choose from a variety of species, finishes, and patterns. Experience elegance and charm underfoot. Discover the perfect flooring solution at Decorama
                </p>

            

                <div className='flex flex-col md:flex-row items-center  mt-4 gap-6'>
                    <button className=' text-white font-semibold py-3  w-full md:w-1/3 bg-gradient-to-r from-amber-800 to-amber-500 text-center cursor-pointer rounded-xl h-full'> <i class="ri-download-2-line pr-2"></i> <span>Brochure</span></button>
                    <Link to="/distributers" className='cursor-pointer'>Our Vendors</Link>
                </div>
            </div>
          </div>
        </div>



        <div class="bg-gradient-to-br from-gray-500 to-gray-600 py-16 md:bg-gradient-to-r bg-cover " id="brands">
  <div class="container m-auto px-6 text-center md:px-12 lg:px-20">
    <h2 class="mb-8 text-4xl font-bold text-white md:text-4xl">
      Brands We Offer
    </h2>
    <div className="flex gap-6 justify-center flex-wrap">
<img src={Tesa} alt="" className='w-[8rem] h-[5rem] md:w-[10rem] md:h-[6rem] xl:w-[14rem] xl:h-[7rem] object-cover'/>
<img src={Quick} alt="" className='w-[8rem] h-[5rem] md:w-[10rem] md:h-[6rem] xl:w-[14rem] xl:h-[7rem] object-cover'/>
<img src={Casa} alt="" className='w-[8rem] h-[5rem] md:w-[10rem] md:h-[6rem] xl:w-[14rem] xl:h-[7rem] object-cover'/>
<img src={Excel} alt="" className='w-[8rem] h-[5rem] md:w-[10rem] md:h-[6rem] xl:w-[14rem] xl:h-[7rem] object-cover'/>
    </div>
  </div>
</div>
                                    




</div>
</div>
  )
}

export default WoodenFlooring